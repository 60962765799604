/* eslint-disable no-unused-vars */
import React from 'react'
import { Reveal, Tween } from 'react-gsap'
import { useMediaQuery } from 'react-responsive'
import emailjs from '@emailjs/browser'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import InputField from './InputField'
import axios from 'axios'
import { toast } from 'react-toastify'

export const CreateJobPosition = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const isDesktop = useMediaQuery({ minWidth: 1440 })

  const formik = useFormik({
    initialValues: {
      from_name: '',
      phone_number: '',
      company_name: '',
      role_recruited: '',
      reply_to: ''
    },
    onSubmit: (values: any) => {
      console.log(values)
      formik.setSubmitting(true)

      axios
        .post('https://api.emailjs.com/api/v1.0/email/send', {
          service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID?.trim(),
          template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID_JOB?.trim(),
          user_id: process.env.REACT_APP_EMAILJS_PUBLIC_KEY?.trim(),
          template_params: values
        })
        .then((res) => {
          console.log(res)
          formik.setValues({
            from_name: '',
            phone_number: '',
            company_name: '',
            role_recruited: '',
            reply_to: ''
          })
          formik.setTouched({
            from_name: false,
            phone_number: false,
            company_name: false,
            role_recruited: false,
            reply_to: false
          })
          formik.setSubmitting(false)

          toast.success('Your request has been sent successfully')
        })
        .catch((err) => {
          console.log(err)
          toast.error('Something went wrong')
          formik.setSubmitting(false)
        })
      // emailjs
      //   .sendForm(
      //     "service_dm4chcp",
      //     "template_st5bep9",
      //     values,
      //     "user_Ti1883HpJziBeQKPoTIFn"
      //   )
      //   .then(
      //     (result) => {
      //       console.log(result);
      //     },
      //     (error) => {
      //       console.log(error);
      //     }
      //   );
    },
    validationSchema: Yup.object({
      from_name: Yup.string().required(),
      phone_number: Yup.string().required(),
      company_name: Yup.string().required(),
      role_recruited: Yup.string().required(),
      reply_to: Yup.string().required()
    })
  })

  return (
    <Reveal threshold={0.15}>
      <Tween to={{ opacity: 1, translateY: 0 }} duration={1.5}>
        <div className="opacity-10 transform translate-y-[5px] max-w-[834px] tablet:max-w-[676px] tablet:ml-[5px] w-full 4k:max-w-[75%] 4k:flex-1 phone:mt-[32px] p-[24px] rounded-[16px] bg-white  4k:h-full 4k:p-[52px] phone:h-full">
          <h3 className="text-secondary-950 font-bold text-h4 mb-[8px] 4k:text-h1">
            Create Job Position
          </h3>
          <p className="text-bodysmall text-ambit-light text-secondary-900 4k:text-h3">
            Please fill this form to continue next step
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="phone:block grid tablet:grid-cols-2 grid-cols-3 gap-x-[16px] 4k:gap-x-[42px] w-full mt-[32px] tablet:mt-[27px] 4k:mt-[52px] phone:space-y-[24px]">
              <InputField
                name="from_name"
                label="Your Name"
                placeholder="Input your name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.from_name}
                value={formik.values.from_name}
                touched={formik.touched.from_name}
              />
              <InputField
                name="reply_to"
                label="Your Email"
                placeholder="Input your Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.reply_to}
                value={formik.values.reply_to}
                touched={formik.touched.reply_to}
              />
              {isDesktop && (
                <InputField
                  name="phone_number"
                  label="Phone Number"
                  placeholder="Input your phone number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.phone_number}
                  value={formik.values.phone_number}
                  touched={formik.touched.phone_number}
                />
              )}
            </div>
            <div className="phone:block grid grid-cols-2 gap-x-[16px] 4k:gap-x-[4 2px] w-full mt-[22px] 4k:mt-[42px] phone:space-y-[24px]">
              <InputField
                name="company_name"
                label="Company Name"
                placeholder="Input your company name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.company_name}
                value={formik.values.company_name}
                touched={formik.touched.company_name}
              />
              <InputField
                name="role_recruited"
                label="Role Recruited"
                placeholder="Input your role recruited"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.role_recruited}
                value={formik.values.role_recruited}
                touched={formik.touched.role_recruited}
              />
            </div>
            <div>
              <button
                type="submit"
                className="4k:text-h3 4k:py-[32px] 4k:rounded-[10px] w-full block text-center py-[16px] bg-info-main font-bold text-white text-ambit-bold rounded-[6px] mt-[24px] 4k:mt-[32px] phone:mt-[32px] disabled:bg-info-hover"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
          {/* <img
            className="4k:w-full"
            src={
              isMobile
                ? `/assets/images/landing-source-mobile.png`
                : `/assets/images/landing-form-desktop.png`
            }
            alt=""
          /> */}
        </div>
      </Tween>
    </Reveal>
  )
}

export const Description = () => {
  return (
    <Reveal>
      <Tween to={{ opacity: 1, translateY: 0 }} duration={1.25}>
        <div className="opacity-0 transform translate-y-[5px]" >
          <p className="font-bold text-ambit-bold text-white opacity-[0.8] text-bodylarge 4k:text-h1">
            Create Job Position
          </p>
          <p className="text-bodymedium tablet:text-bodysmall text-ambit-light text-white opacity-[56%] mt-[16px]  4k:mt-[32px] 4k:text-h3 4k:max-w-[500px]">
            Clearly defining a role and its expectations is crucial to attracting the right candidates.
            <span className="mt-[12px] block">
              As part of our recruitment service in Australia, we will work alongside your team to assist in creating an accurate and compelling job description.
            </span>
          </p>
        </div>
      </Tween>
    </Reveal>
  )
}
