import { FixedHeader, Footer, Header } from 'components'
import React from 'react'
import Helmet from 'react-helmet'

import Connect from './components/Connect'
import FeaturedIn from './components/FeaturedIn'
import Hero from './components/Hero'
import Team from './components/Team'
import Represent from './components/Represent'
import RecruitmentAgency from './components/RecruitementAgency'
import StandFor from './components/StandFor'

const About: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>About Us Blended Employment | Recruitment Agency</title>
        <meta name='description' content='Blended Employment is a professional sales and marketing recruitment agency. We assist you in finding the right talent to represent your business to the next level.' />
        <meta name='keywords' content='recruitment agency' />
        <link rel='canonical' href='https://blendedemployment.com.au/about-us' />
      </Helmet>
      <div className="pt-[32px] phone:pt-[2px] bg-primary overflow-hidden">
        <Header />
        <div className="4k:scale-[2.3] 4k:py-[700px]">
          <Hero />
        </div>
      </div>
      <div className="4k:scale-[2.3] 4k:h-[10000px] 4k:translate-y-[6450px]">
        <Connect />
        <Team />
        <Represent />
        <RecruitmentAgency />
        <StandFor />
        <FeaturedIn />
      </div>
      <Footer />
      <FixedHeader />
    </>
  )
}

export default About
