import React from 'react'
import { Reveal, Timeline, Tween } from 'react-gsap'

export default function FeaturedIn () {
  return (
    <section className="py-[120px] tablet:py-[80px] phone:py-[56px] relative tablet:max-w-[912px] mx-auto w-[90%]">
      {/* <img
        src="/assets/images/shapes/about-shape-bg.svg"
        className="absolute scale-[10] top-[0px] phone:top-[300px] phone:left-[120px] phone:scale-[6]  z-[-1]"
        alt=""
      /> */}
      <Reveal>
        <div className="text-center mb-[56px] phone:mb-[40px]">
          <Timeline
            target={
              <>
                <p className="text-h2 tablet:text-h3 text-ambit-bold font-bold text-secondary-950 mb-[24px]">
                  Featured In
                </p>
                <p className="max-w-[591px] phone:max-w-[327px] mx-auto mt-[24px] text-bodymedium text-ambit-light text-secondary-900">
                  Like any successful company, our values are the foundation of
                  our businees. From the very principles our business is built
                  on to the way we operate.
                </p>
              </>
            }
          >
            <Tween
              from={{ opacity: 0, transform: 'translateY(-20px)' }}
              duration={0.6}
              target={0}
            />
            <Tween
              from={{ opacity: 0, transform: 'translateY(-20px)' }}
              duration={0.6}
              target={1}
            />
          </Timeline>
        </div>
        <div className="max-w-[1120px] mx-auto grid grid-cols-4 phone:grid-cols-2 gap-x-[53px] gap-y-[64px] phone:gap-x-[8px] phone:gap-y-[24px]">
          <Timeline
            target={
              <>
                <div className="featured w-[240px] tablet:w-[200px] phone:w-[160px] phone:scale-[0.7] flex justify-center items-center">
                  <img src="/assets/images/clients/featured-1.png" alt="" />
                </div>
                <div className="featured w-[240px] tablet:w-[200px] phone:w-[160px] phone:scale-[0.7] flex justify-center items-center">
                  <img src="/assets/images/clients/featured-2.png" alt="" />
                </div>
                <div className="featured w-[240px] tablet:w-[200px] phone:w-[160px] phone:scale-[0.7] flex justify-center items-center">
                  <img src="/assets/images/clients/featured-3.png" alt="" />
                </div>
                <div className="featured w-[240px] tablet:w-[200px] phone:w-[160px] phone:scale-[0.7] flex justify-center items-center">
                  <img src="/assets/images/clients/featured-4.svg" alt="" />
                </div>
                <div className="featured w-[240px] tablet:w-[200px] phone:w-[160px] phone:scale-[0.7] flex justify-center items-center">
                  <img src="/assets/images/clients/featured-5.png" alt="" />
                </div>
                <div className="featured w-[240px] tablet:w-[200px] phone:w-[160px] phone:scale-[0.7] flex justify-center items-center">
                  <img src="/assets/images/clients/featured-6.png" alt="" />
                </div>
                <div className="featured w-[240px] tablet:w-[200px] phone:w-[160px] phone:scale-[0.7] flex justify-center items-center">
                  <img src="/assets/images/clients/featured-7.png" alt="" />
                </div>
                <div className="featured w-[240px] tablet:w-[200px] phone:w-[160px] phone:scale-[0.7] flex justify-center items-center">
                  <img src="/assets/images/clients/featured-8.svg" alt="" />
                </div>
              </>
            }
          >
            <Tween
              from={{ opacity: 0, transform: 'translateY(-10px)' }}
              duration={0.4}
              target={0}
            />
            <Tween
              from={{ opacity: 0, transform: 'translateY(-10px)' }}
              duration={0.4}
              target={1}
            />
            <Tween
              from={{ opacity: 0, transform: 'translateY(-10px)' }}
              duration={0.4}
              target={2}
            />
            <Tween
              from={{ opacity: 0, transform: 'translateY(-10px)' }}
              duration={0.4}
              target={3}
            />
            <Tween
              from={{ opacity: 0, transform: 'translateY(-10px)' }}
              duration={0.4}
              target={4}
            />
            <Tween
              from={{ opacity: 0, transform: 'translateY(-10px)' }}
              duration={0.4}
              target={5}
            />
            <Tween
              from={{ opacity: 0, transform: 'translateY(-10px)' }}
              duration={0.4}
              target={6}
            />
            <Tween
              from={{ opacity: 0, transform: 'translateY(-10px)' }}
              duration={0.4}
              target={7}
            />
          </Timeline>
        </div>
      </Reveal>
    </section>
  )
}
