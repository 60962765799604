import useFixedHeader from "components/FixedHeaderContext/useFixedHeader";
import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import menus from "./data";
import MobileHeader from "./MobileHeader";

export default function FixedHeader({ classes }: { classes?: string }) {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const activePage = useLocation();

  // const [isFixedHeader, setShowFixedHeader] = useState(false);
  const [showFixedHeader, setShowFixedHeader] = useFixedHeader();

  function makeHeaderFixed() {
    if (window?.top?.scrollY ?? 0 > 400) {
      setShowFixedHeader(true);
    } else {
      setShowFixedHeader(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    document.addEventListener("scroll", makeHeaderFixed);

    return () => {
      document.removeEventListener("scroll", makeHeaderFixed);
    };
  }, []);

  if (isMobile) {
    return <MobileHeader isFixedHeader={showFixedHeader} />;
  }

  return (
    <>
      <header
        className={[
          "tablet:mx-[56px] 4k:mx-[120px] tablet:block 2xl:block transition-all fixed  top-[32px] w-full max-w-[87.8%] z-50 4k:max-w-[3600px] tablet:max-w-[912px] left-0 right-0  mx-auto ",
          classes,
          showFixedHeader ? "show-down" : "opacity-0",
        ].join(" ")}
      >
        <div className="px-[32px] py-[16px] 4k:px-[64px] 4k:py-[32px] flex items-center justify-between bg-white shadow-header">
          <div className="logo">
            <Link to="/">
              <img
                src="/assets/images/logo.png"
                className="w-[98px] 4k:w-[230px]"
                alt=""
              />
            </Link>
          </div>
          <div className="flex items-center justify-between">
            <ul className="flex items-center justify-between mr-[96px] tablet:mr-[73px]">
              {menus.map((menu, index) => {
                return (
                  <li
                    key={index}
                    className="mr-[32px] 4k:mr-[74px] tablet:mr-[24px]"
                  >
                    <Link
                      to={menu.link}
                      className={[
                        "font-semibold text-bodysmall 4k:text-h3",
                        menu.link === activePage.pathname
                          ? "text-info-main"
                          : "text-secondary-600",
                      ].join(" ")}
                    >
                      {menu.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul className="flex justify-between items-center">
              <li className="4k:mr-[20px]">
                <a
                  href="tel:0736678895"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black font-bold mr-[24px] tablet:mr-[10px] text-bodysmall 4k:text-h3 flex items-center"
                >
                  <img
                    src="/assets/icons/ic-australia.svg"
                    className="w-[24px] mr-[8px] tablet:mr-[10px] 4k:w-[42px] inline-block"
                    alt=""
                  />
                  07 3667 8895
                </a>
              </li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://calendly.com/jordan-gko4"
                className="btn 4k:text-h3"
              >
                Book a meeting
              </a>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}
