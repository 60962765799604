import { Header, Footer, FixedHeader } from 'components'
import React from 'react'
import Helmet from 'react-helmet'

import Hero from './components/Hero'
import JobListing from './components/JobListing'

const JobBoard: React.FC = () => {
  return (
    <div className="mt-[32px]">
      <Helmet>
        <title>Job Board Blended Employment | Best Job Sites Australia</title>
        <meta name='description' content='We are a specialist recruitment and executive search firm. Find various jobs at Blended Employment. Contact our teams for details!'/>
        <meta name='keywords' content='executive search firm, best job sites australia'/>
        <link rel='canonical' href='https://blendedemployment.com.au/job-board' />
      </Helmet>

      <Header />
      <Hero />
      <div className="bg-white pt-[120px] tablet:pt-[80px] phone:pt-[56px] relative z-20">
        <JobListing />
      </div>
      <Footer />
      <FixedHeader />
    </div>
  )
}
export default JobBoard
