import React, { useState } from 'react'
import { Reveal, Tween } from 'react-gsap'

export const SourceCandidate = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <Reveal>
      <Tween to={{ opacity: 1, translateY: 0 }} duration={1.5}>
        <div className="w-full opacity-10 transform translate-y-[5px] max-w-[834px] phone:mt-[32px]  4k:max-w-[75%] 4k:flex-1">
          <div className={`${isLoaded ? 'hidden' : 'block'} animate-pulse w-full h-[456px]`}>
            <div className="bg-[#EDF1F6] w-full h-full rounded-[6px]" />
          </div>
          <img
            className={`${isLoaded ? 'block' : 'hidden'} 4k:w-full`}
            src={require('assets/images/home/how-it-works/source-candidate.png')}
            alt=""
            onLoad={() => setIsLoaded(true)}
          />
        </div>
      </Tween>
    </Reveal>
  )
}

export const Description = () => {
  return (
    <Reveal>
      <Tween to={{ opacity: 1, translateY: 0 }} duration={1.25}>
        <div className="opacity-10 transform translate-y-[5px]" >
          <p className="font-bold text-ambit-bold text-white opacity-[0.8] text-bodylarge 4k:text-h1">
            Conduct Candidate Research & Checks
          </p>
          <p className="text-bodymedium tablet:text-bodymedium text-ambit-light text-white opacity-[56%] mt-[16px]  4k:mt-[32px] 4k:text-h3 4k:max-w-[500px]">
            As a comprehensive recruitment agency, we focus on sourcing talent who are guaranteed to meet your business needs and exceed your expectations.
            <span className="mt-[12px] block">
              In order to do so, we follow a detailed screening process to ensure we present you with only the best selection of candidates.
            </span>
          </p>
        </div>
      </Tween>
    </Reveal>
  )
}
