import { Timeline, Tween } from 'react-gsap'
import { useMediaQuery } from 'react-responsive'
import React from 'react'
import JobSearchForm from './JobSearchForm'

const Hero = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  return (
    <section className="hero-job pb-[115px] phone:pb-[64px] bg-secondary-100 relative">
      <img
        src="/assets/images/shapes/job-hero-shape.svg"
        className="absolute w-full z-10 top-[-331px] largeDesktop:top-[-532px] left-[214px] scale-[2] 3xl:top-[-961px] phone:left-[88px] phone:top-[207px] phone:scale-[2] tablet:top-[-300px]"
        alt=""
      />

      <div className="max-w-[1120px] 4k:max-w-[2300px] tablet:max-w-[912px] phone:max-w-[327px] w-[90%] mx-auto phone:block flex justify-between items-center mt-[104px] tablet:mt-[117px] phone:mt-[63px] relative z-20">
        <div className="max-w-[573px] 4k:max-w-[50%] tablet:w-[440px] phone:w-full">
          <Timeline
            target={
              <>
                <p className="text-secondary-600 text-bodymedium phone:text-bodysmall phone:text-center text-ambit-semibold font-semibold mb-[24px] 4k:text-h2">
                  {' '}
                  ‍💻 New jobs added every day
                </p>
                <h1 className="text-h1 4k:text-[120px] 4k:leading-[100px] 4k:my-[70px] phone:text-h3 phone:text-center text-ambit-bold text-secondary-950 mb-[24px]">
                  Find your next role.
                </h1>
                <p className="text-bodylarge tablet:text-bodymedium text-ambit-light text-secondary-900 mb-[48px] phone:mb-[40px] max-w-[527px] phone:max-w-[97%] phone:text-center  4k:text-h2 4k:max-w-[80%]">
                  {isMobile
                    ? 'We help you source, match and hire sales and marketing talent faster.'
                    : `Here at blended employment we strive to ensure we have roles
            available at all levels across all locations throughout Australia.`}
                </p>
                <div>
                  <JobSearchForm />
                </div>
              </>
            }
          >
            <Tween from={{ opacity: 0, transform: 'translateX(-30px)' }} />
          </Timeline>
        </div>
        <Tween from={{ opacity: 0, transform: 'translateX(30px)' }}>
          <div className="hero-image relative phone:mt-[56px]  4k:w-[40%]">
            <img
              style={{
                boxShadow: isMobile
                  ? '-8px 6px 0px rgba(0,0,0,1)'
                  : '-10px 8px 0px rgba(0,0,0,1)'
              }}
              src="/assets/images/job-actor.png"
              className="block rounded-[6px] 4k:w-full"
              alt=""
            />
            <div className="border-[2px] border-secondary-950 rounded-[6px] absolute bottom-[16px] w-full py-[16px] px-[24px] 4k:py-[32px] 4k:px-[42px] bg-white w-[352px] mx-auto left-0 right-0 phone:w-[285px] 4k:w-[860px] 4k:bottom-[32px]">
              <p className="text-primary-pressed text-bodymedium text-ambit-bold font-bold flex items-center">
                <span className="mr-[10px] text-info-pressed text-bodymedium font-bold text-ambit-bold 4k:text-h2">
                  Alex Cooper
                </span>
                <img
                  src="/assets/icons/ic-linkedin-gray.svg"
                  className="4k:w-[40px]"
                  alt=""
                />
              </p>
              <p className="text-bodymedium 4k:text-h3 font-light text-ambit-light text-[rgba(5, 18, 55, 0.6)]">
                Executive Recruiter
              </p>
            </div>
          </div>
        </Tween>
      </div>

    </section>
  )
}

export default Hero
