import React from 'react'
import PaginationItem from './PaginationItem'

const Pagination = ({
  totalData,
  currentPage,
  totalPage,
  handlePagination
}: {
  totalData?: number | any;
  currentPage?: number | any;
  totalPage?: number | any;
  handlePagination: (page: number) => void;
}) => {
  const paginations = []

  let startPage, endpage
  if (totalPage <= 8) {
    startPage = 1
    endpage = totalPage
  } else {
    if (currentPage <= 6) {
      startPage = 1
      endpage = 10
    } else if (currentPage + 4 >= totalPage) {
      startPage = totalPage - 9
      endpage = totalPage
    } else {
      startPage = currentPage - 5
      endpage = currentPage + 4
    }
  }

  if (totalPage === 1) {
    paginations.push(
      <PaginationItem
        isActive={true}
        key={1}
        number={1}
        handlePagination={() => handlePagination(1)}
      />
    )
  } else {
    for (let number = startPage; number <= endpage; number++) {
      if (number === currentPage) {
        paginations.push(
          <PaginationItem
            isActive={true}
            key={number}
            number={number}
            handlePagination={() => handlePagination(number)}
          />
        )
      } else {
        paginations.push(
          <PaginationItem
            isActive={false}
            key={number}
            number={number}
            handlePagination={() => handlePagination(number)}
          />
        )
      }
    }
  }

  if (totalData > 0) {
    return (
      <div className="flex items-center justify-between phone:mt-4 mt-[48px]  phone:flex-col phone:space-y-[24px] ">
        <p className="text-xs tablet:text-[12px] desktop:text-[12px] md:text-[12px] text-secondary-600 text-caption-light text-ambit-light font-light 4k:text-h2">
          Showing {currentPage} to {totalPage} of {totalData} entries
        </p>
        <div className="pagination tablet:text-[12px] md:text-base space-x-[8px] phone:space-x-1 space-y-[10px] 4k:scale-[1.4] 4k:translate-x-[-120px]">
          <button
            key={'prev'}
            onClick={() =>
              currentPage > 1 && handlePagination(currentPage - 1)
            }
            className="text-xs tablet:text-[12px] md:text-base py-[8px] px-[13px] phone:px-[10px] border border-secondary-300 rounded-[6px] text-caption-light text-secondary-600"
          >
            Previous
          </button>

          {paginations}
          {/* <button className="py-[8px] px-[15px] border border-secondary-300 rounded-[6px] text-caption-light bg-button border-button text-white">
          1
        </button>
        <button className="py-[8px] px-[15px] border border-secondary-300 rounded-[6px] text-caption-light text-secondary-600">
          2
        </button>
        <button className="py-[8px] px-[15px] border border-secondary-300 rounded-[6px] text-caption-light text-secondary-600">
          ...
        </button> */}
          <button
            key="next"
            onClick={() =>
              currentPage < totalPage && handlePagination(currentPage + 1)
            }
            className="text-xs tablet:text-[12px] md:text-base py-[8px] px-[13px] phone:px-[10px] border border-secondary-300 rounded-[6px] text-caption-light text-secondary-600"
          >
            Next
          </button>
        </div>
      </div>
    )
  } else return null
}

export default Pagination
