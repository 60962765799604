import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Reveal, Timeline, Tween } from 'react-gsap'

const Sourced = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  return (
    <div className="phone:block flex items-center ">
      <Reveal threshold={0.025}>
        <Timeline
          target={
            <>
              <img
                src="/assets/images/landing-desktop-sourced.png"
                alt=""
                className="max-w-[739px] tablet:max-w-[661px] mt-[88px] tablet:mt-[110px] 4k:mt-[150px] w-full 4k:max-w-[1600px]"
              />
            </>
          }
        >
          <Tween
            from={{ opacity: 0, transform: 'translateX(-30px)' }}
            duration={0.6}
          />
          <div className="ml-[119px] tablet:ml-[48px] phone:max-w-[203px] phone:mt-[32px] tablet:mt-[115px] 4k:max-w-[500px]">
            <Timeline
              target={
                <>
                  <p className="font-bold text-h4 text-ambit-bold text-white tablet:text-bodylarge 4k:text-h1">
                    Sourced
                  </p>
                  <p className="text-bodymedium tablet:text-bodysmall text-ambit-light text-white opacity-[80%] mt-[16px] 4k:text-h3 4k:leading-[50px]">
                    {isMobile
                      ? 'Manage all candidates who register into the system. With a cv, recruiters immediately see which candidate applied first, and the recruiter will contact the candidates.'
                      : `Thanks to our Ai powered CRM built on Web3, relationships,
                networking and brand presence we're able to source the best
                passive talent available.`}
                  </p>
                </>
              }
            >
              <Tween
                from={{ opacity: 0, transform: 'translateY(20px)' }}
                duration={0.6}
                target={0}
              />
              <Tween
                from={{ opacity: 0, transform: 'translateY(20px)' }}
                duration={0.6}
                target={1}
              />
            </Timeline>
          </div>
        </Timeline>
      </Reveal>
    </div>
  )
}

export default Sourced
