import React, { useState } from 'react'
import { Reveal, Tween } from 'react-gsap'

export function SecureCandidate () {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <Reveal>
      <Tween to={{ opacity: 1, translateY: 0 }} duration={1.5}>
        <div className="w-full opacity-10 transform translate-y-[5px] max-w-[834px] phone:mt-[32px] 4k:max-w-[75%] 4k:flex-1">
          <div className={`${isLoaded ? 'hidden' : 'block'} animate-pulse w-full h-[456px]`}>
            <div className="bg-[#EDF1F6] w-full h-full rounded-[6px]" />
          </div>
          <img
            className={`${isLoaded ? 'block' : 'hidden'} 4k:w-full`}
            src={require('assets/images/home/how-it-works/secure-candidate.png')}
            alt=""
            onLoad={() => setIsLoaded(true)}
          />
        </div>
      </Tween>
    </Reveal>
  )
}

export const Description = () => {
  return (
    <Reveal>
      <Tween to={{ opacity: 1, translateY: 0 }} duration={1.25}>
        <div className="opacity-10 transform translate-y-[5px]" >
          <p className="font-bold text-ambit-bold text-white opacity-[0.8] text-bodylarge 4k:text-h1">
            Secure Your Candidate
          </p>
          <p className="text-bodymedium tablet:text-bodysmall text-ambit-light text-white opacity-[56%] mt-[16px]  4k:mt-[32px] 4k:text-h3 4k:max-w-[500px]">
            From salary negotiations to complete reference checks, our expert recruitment team will happily take care of the time-consuming tasks required to make the right decision.
            <span className="mt-[12px] block">
              Whatever is required to assist you in the candidate selection process, we will happily assist with.
            </span>
          </p>
        </div>
      </Tween>
    </Reveal>
  )
}
