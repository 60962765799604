/* eslint-disable no-unused-vars */
import dayjs from "lib/Dayjs";
import React, { useState } from "react";
import ApplyModal from "./ApplyModal";
import useDownloadTemplate from "hooks/useDownloadTemplate";

const JobDesc = ({
  data,
  isLoading,
  setShowApplyModal,
}: {
  data: any;
  isLoading: boolean;
  setShowApplyModal: (value: boolean) => void;
}) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleApplyJob = () => {
    setShowApplyModal(true);
  };

  const downloadTemplate = useDownloadTemplate();

  return (
    <>
      <div className="max-w-[736px] w-full tablet:max-w-[597px] phone:max-w-[327px] bg-white">
        <div className="border border-black rounded-[6px] p-[32px] phone:p-[24px] flex flex-col justify-between space-y-[24px]">
          <div className="flex justify-between items-center phone:flex-col phone:space-y-[8px] phone:items-start">
            <h1 className="text-h3 phone:text-h4 font-bold text-secondary-950 text-ambit-bold">
              {data?.title}
            </h1>
            <div
              className="flex items-center py-[8px] px-[16px] rounded-full"
              style={{ background: "rgba(3, 208, 176, 0.06)" }}
            >
              <img
                src="/assets/icons/ic-calendar-success.svg"
                className="mr-[10px]"
                alt=""
              />
              <span className="text-bodymedium font-semibold text-ambit-semibold text-success-main">
                {dayjs(data?.created_at).format("Do MMM, YYYY")}
              </span>
            </div>
          </div>
          <div className="h-[1px] bg-secondary-300"></div>
          <div className="flex bg-[#F7F8FB] justify-between py-[24px] px-[32px] phone:p-[16px] phone:flex-col phone:space-y-[16px] border border-black rounded-[6px]">
            <div className="flex items-center">
              <img
                src="/assets/icons/ic-map-dark.svg"
                className="mr-[10px]"
                alt=""
              />
              <p className="text-bodysmall font-light text-ambit-light text-secondary-950">
                {data.address}
              </p>
            </div>
            <div className="flex items-center">
              <img
                src="/assets/icons/ic-discover.svg"
                className="mr-[10px]"
                alt=""
              />
              <p className="text-bodysmall font-light text-ambit-light text-secondary-950">
                {data?.job_type.name}
              </p>
            </div>
            <div className="flex items-center">
              <img
                src="/assets/icons/ic-profile-tick.svg"
                className="mr-[10px]"
                alt=""
              />
              <p className="text-bodysmall font-light text-ambit-light text-secondary-950">
                {data?.category?.name ?? "Unknown category"}
              </p>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html:
                data?.description ?? "<p>No Description for this job...</p>",
            }}
            className="job_description"
          ></div>
        </div>
        <div className="w-full my-[24px] phone:p-[24px] p-[32px] border border-[#0C0A1A] rounded-[6px] grid gap-[24px]">
          <div className="w-full grid gap-[12px]">
            <h1 className="font-semibold font-manrope text-[#0C0A1A] text-[20px] leading-[32px]">
              Resume Template
            </h1>
            <p className="text-[16px] leading-[24px] text-[#0C0A1A] font-manrope font-light">
              Crafted by Australian recruitment experts, our template ensures
              your resume is structured simply yet effectively, presenting all
              the essential information to make you stand out.
            </p>
          </div>
          <button
            onClick={downloadTemplate}
            className="w-fit btn-hover hover:text-white text-[#3377FF] py-[12px] px-[24px] rounded-full font-semibold font-manrope border border-[#3377FF] flex items-center gap-[6px] bg-white hover:bg-[#3377FF] transition-all duration-200"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 14.792C7.41667 14.792 7.34167 14.7753 7.25833 14.742C7.025 14.6503 6.875 14.417 6.875 14.167V9.16699C6.875 8.82533 7.15833 8.54199 7.5 8.54199C7.84167 8.54199 8.125 8.82533 8.125 9.16699V12.6587L8.725 12.0587C8.96667 11.817 9.36667 11.817 9.60833 12.0587C9.85 12.3003 9.85 12.7003 9.60833 12.942L7.94167 14.6087C7.825 14.7253 7.65833 14.792 7.5 14.792Z"
                fill="#3377FF"
              />
              <path
                d="M7.50003 14.7915C7.3417 14.7915 7.18337 14.7332 7.05837 14.6082L5.3917 12.9415C5.15003 12.6999 5.15003 12.2999 5.3917 12.0582C5.63337 11.8165 6.03337 11.8165 6.27503 12.0582L7.9417 13.7249C8.18337 13.9665 8.18337 14.3665 7.9417 14.6082C7.8167 14.7332 7.65837 14.7915 7.50003 14.7915Z"
                fill="#3377FF"
              />
              <path
                d="M12.4998 18.9587H7.49984C2.97484 18.9587 1.0415 17.0253 1.0415 12.5003V7.50033C1.0415 2.97533 2.97484 1.04199 7.49984 1.04199H11.6665C12.0082 1.04199 12.2915 1.32533 12.2915 1.66699C12.2915 2.00866 12.0082 2.29199 11.6665 2.29199H7.49984C3.65817 2.29199 2.2915 3.65866 2.2915 7.50033V12.5003C2.2915 16.342 3.65817 17.7087 7.49984 17.7087H12.4998C16.3415 17.7087 17.7082 16.342 17.7082 12.5003V8.33366C17.7082 7.99199 17.9915 7.70866 18.3332 7.70866C18.6748 7.70866 18.9582 7.99199 18.9582 8.33366V12.5003C18.9582 17.0253 17.0248 18.9587 12.4998 18.9587Z"
                fill="#3377FF"
              />
              <path
                d="M18.3332 8.95841H14.9998C12.1498 8.95841 11.0415 7.85007 11.0415 5.00007V1.66674C11.0415 1.41674 11.1915 1.18341 11.4248 1.09174C11.6582 0.991739 11.9248 1.05007 12.1082 1.22507L18.7748 7.89174C18.9498 8.06674 19.0082 8.34174 18.9082 8.57507C18.8082 8.8084 18.5832 8.95841 18.3332 8.95841ZM12.2915 3.17507V5.00007C12.2915 7.15007 12.8498 7.70841 14.9998 7.70841H16.8248L12.2915 3.17507Z"
                fill="#3377FF"
              />
            </svg>
            <p className="text-[16px] leading-[24px] font-semibold ">
              Download Template
            </p>
          </button>
        </div>
        <button
          onClick={handleApplyJob}
          className="py-[12px] text-bodymedium font-semibold text-ambit-semibold text-white bg-button w-full rounded-[6px]"
        >
          Apply This Job
        </button>
      </div>
    </>
  );
};

export default JobDesc;
