import React from "react";
import { Reveal, Timeline, Tween } from "react-gsap";
import TechnologyDigital from "./TechnologyDigital";

import CommercialIndustries from "./CommercialIndustries";
import PropertyRealEstate from "./PropertyRealEstate";

const Sector = () => {
  const handleStartHiring = () => {
    window.open("https://calendly.com/jordan-gko4", "_blank");
  };

  return (
    <div className="bg-white relative z-20 4k:scale-[2.3] 4k:h-[8000px] 4k:translate-y-[5300px]">
      <div className="max-w-[1120px] phone:max-w-[327px] phone:w-full w-[90%] tablet:max-w-[912px] mx-auto pt-[120px] largeDesktop:pt-[100px] phone:pt-[56px] tablet:pt-[80px]">
        <div className="space-y-[24px] phone:space-y-[12px]">
          <Reveal>
            <Timeline
              target={
                <>
                  <h2 className="text-h2 tablet:text-h3 font-bold text-ambit-bold text-secondary-950 text-center">
                    We Handle 3 Major Sectors
                  </h2>
                  <p className="text-bodymedium tablet:text-bodysmall phone:text-bodymedium text-ambit-light text-secondary-950 text-center max-w-[736px] tablet:max-w-[598px] mx-auto">
                    Whether you’re a small start-up or a multi-national
                    corporation, no project is too complex for our expert team.
                    From Tech & Digital, Commercial & Industrial to Real Estate
                    & Property,
                    <span className="font-semibold text-ambit-semibold text-ambit-light-phone phone:font-normal">
                      {" "}
                      we offer expert sales and marketing recruitment in
                      Australia.
                    </span>
                  </p>
                </>
              }
            >
              <Tween
                from={{ opacity: 0, transform: "translateY(-20px)" }}
                duration={0.4}
                target={0}
              />
              <Tween
                from={{ opacity: 0, transform: "translateY(-20px)" }}
                duration={0.4}
                target={1}
              />
            </Timeline>
          </Reveal>
        </div>
        <Reveal>
          <Tween from={{ opacity: 0, transform: "translateY(30px)" }}>
            <div className="relative overflow-x-scroll">
              <div className="flex justify-center mt-[80px] tablet:mt-[56px] phone:mt-[24px] phone:justify-start tablet:mx-auto">
                <img
                  src="/assets/images/industry-section-1.png"
                  className="mr-[32px] phone:mr-[20px] w-full tablet:max-w-[519px] phone:min-w-[327px] phone:h-[202px]"
                  alt=""
                />
                <img
                  src="/assets/images/industry-section-2.png"
                  className="w-full tablet:max-w-[361px] phone:h-[202px]"
                  alt=""
                />
              </div>
            </div>
          </Tween>
        </Reveal>

        <TechnologyDigital handleStartHiring={handleStartHiring} />

        {/* image shape */}
        <div>
          <img
            src="/assets/images/shapes/industry-hero-shape-2.svg"
            className="absolute scale-[2] tablet:scale-[3] z-10 top-[402px] tablet:top-[700px] phone:top-[1230px] phone:scale-x-[6.5] phone:scale-y-[4] phone:left-[500px] phone:opacity-[0.5]"
            alt=""
          />
          <img
            src="/assets/images/shapes/industry-hero-shape-2.svg"
            className="absolute scale-[3] z-10 top-[1894px] tablet:top-[1978px] phone:top-[2544px] phone:scale-x-[6.5] phone:scale-y-[4] phone:left-[500px] phone:opacity-[0.6]"
            alt=""
          />
        </div>

        <CommercialIndustries handleStartHiring={handleStartHiring} />

        <PropertyRealEstate handleStartHiring={handleStartHiring} />
      </div>
    </div>
  );
};

export default Sector;
