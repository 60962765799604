import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'
import Tablet from './Tablet'
import Mobile from './Mobile'

const SkeletonCard = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const isTablet = useMediaQuery({ maxWidth: 1024 })

  if (isMobile) {
    return <Mobile />
  }

  if (isTablet) {
    return <Tablet />
  }

  return (
    <SkeletonTheme baseColor="#EDF1F6" highlightColor="#E3EAF3">
      <div className="skeleton-card p-[20px] bg-white rounded-[6px] border ">
        <div className="mb-[15px] flex justify-between">
          <Skeleton className="!w-[160px] !h-[32px] !rounded-[6px]" />
          <Skeleton className="!w-[60px] !rounded-[40px] !h-[32px]" />
        </div>
        <div className="flex">
          <Skeleton className="!w-[128px] !h-[20px] !rounded-[6px] !mr-[16px]" />
          <Skeleton className="!w-[96px] h-[20px] !rounded-[6px]" />
        </div>
        <div className="my-[15px] h-[1px] bg-[#EDF1F6]"></div>
        <div className="">
          <Skeleton className="!w-[123px] !h-[24px] !rounded-[6px] !mb-[14px]" />
          <Skeleton className="!w-[77px] !h-[24px] !rounded-[6px] !mb-[14px]" />
          <Skeleton className="!w-[152px] !h-[24px] !rounded-[6px] !mb-[14px]" />
        </div>
        <Skeleton className="!h-[48px] !rounded-[6px] !mt-[14px]" />
      </div>
    </SkeletonTheme>
  )
}

export default SkeletonCard
