import React, { useState } from 'react'
import { Timeline, Tween, Reveal } from 'react-gsap'
import ImageModal from './ImageModal'

const IndustrySpecialization = () => {
  const [showModal, setShowModal] = useState(false)
  const [activeImage, setActiveImage] = useState('')

  const handleImageModal = (image: string) => {
    setShowModal(!showModal)
    setActiveImage(image)
  }

  return (
    <div className="relative">
      <section className="py-[120px] tablet:py-[80px] phone:py-[56px] bg-secondary-100 relative z-40">
        <div className="max-w-[1120px] 4k:max-w-[2700px] tablet:max-w-[912px] phone:max-w-[327px] mx-auto">
          <Reveal>
            <Timeline
              target={
                <>
                  <h4 className="text-h2 tablet:text-h3 text-secondary-950 font-bold text-ambit-bold 4k:text-[72px]">
                    Our Industry Specialisations
                  </h4>
                  <p className="text-bodymedium text-ambit-light text-secondary-900 max-w-[600px] tablet:max-w-[739px] mt-[16px] tablet:mt-[24px] 4k:text-h3 4k:max-w-[1000px] 4k:mt-[50px]">
                    At Blended Employment, our recruiters are dedicated to
                    bringing people together. As a company built on integrity,
                    excellence and transparency,
                  </p>
                </>
              }
            >
              <Tween
                from={{ opacity: 0, transform: 'translateY(-10px)' }}
                target={0}
                duration={0.4}
              />
              <Tween
                from={{ opacity: 0, transform: 'translateY(-10px)' }}
                target={1}
                duration={0.4}
              />
              <div className="cards grid grid-cols-3 phone:grid-cols-1 phone:gap-y-[32px] gap-x-[25px] tablet:gap-x-[32px] mt-[56px] phone:mt-[32px]">
                <Timeline
                  target={
                    <>
                      <div
                        className="landing-card p-[24px] tablet:p-[16px] border border-black rounded-[16px] cursor-pointer hover:bg-white transition-all 4k:p-[48px]"
                        onClick={() =>
                          handleImageModal('commercial-and-industry.png')
                        }
                      >
                        <img
                          src="/assets/images/landing-card-1a.png"
                          className="w-full hidden xl:block"
                          alt=""
                        />
                        <img
                          src="/assets/images/landing-card-1b.png"
                          className="mt-[16px] w-full hidden xl:block"
                          alt=""
                        />
                        <img
                          src="/assets/images/engagement.png"
                          className="w-full xl:hidden block"
                          alt=""
                        />
                        <p className="text-h4 tablet:text-bodylarge text-secondary-950 font-bold text-ambit-bold mt-[20px] 4k:text-h1 4k:mt-[40px]">
                          Commercial & Industrial
                        </p>
                      </div>
                      <div
                        className="landing-card p-[24px] 4k:p-[48px] tablet:p-[16px] border border-black rounded-[16px] tablet:flex tablet:flex-col tablet:justify-between cursor-pointer hover:bg-white transition-all"
                        onClick={() =>
                          handleImageModal('tech-and-digital-v2.png')
                        }
                      >
                        <p className="text-h4 tablet:text-bodylarge text-secondary-950 font-bold text-ambit-bold mt-[20px] tablet:mt-[0px] 4k:text-h1 4k:mb-[20px]">
                          Technology & Digital
                        </p>
                        <div className="xl:flex xl:items-end">
                          <img
                            src="/assets/images/landing-card-2a.png"
                            className="w-full mr-[18px] tablet:mr-[12px] tablet:w-[108px] hidden xl:block"
                            alt=""
                          />
                          <img
                            src="/assets/images/landing-card-2b-v2.png"
                            className="mt-[16px] w-full tablet:w-[130px] hidden xl:block"
                            alt=""
                          />
                          <img
                            src="/assets/images/techno-industry.png"
                            className="w-full xl:hidden block"
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        className="landing-card p-[24px] 4k:p-[48px] tablet:p-[16px] border border-black rounded-[16px] cursor-pointer hover:bg-white transition-all"
                        onClick={() =>
                          handleImageModal('property-and-real-estate-v2.png')
                        }
                      >
                        <img
                          src="/assets/images/landing-card-3-v2.png"
                          className="w-full tablet:h-[290px] hidden xl:block"
                          alt=""
                        />
                        <img
                          src="/assets/images/blended.png"
                          className="w-full tablet:h-[290px] block xl:hidden"
                          alt=""
                        />

                        <p className="text-h4 tablet:text-bodylarge text-secondary-950 font-bold text-ambit-bold mt-[20px] 4k:text-h1 4k:mt-[40px]">
                          Property & Real Estate
                        </p>
                      </div>
                    </>
                  }
                >
                  <Tween
                    from={{ opacity: 0, transform: 'translateY(30px)' }}
                    target={0}
                    duration={0.6}
                  />
                  <Tween
                    from={{ opacity: 0, transform: 'translateY(30px)' }}
                    target={1}
                    duration={0.6}
                  />
                  <Tween
                    from={{ opacity: 0, transform: 'translateY(30px)' }}
                    target={2}
                    duration={0.6}
                  />
                </Timeline>
              </div>
            </Timeline>
          </Reveal>
        </div>
      </section>
      <ImageModal
        onClick={() => setShowModal(false)}
        showModal={showModal}
        imageSrc={activeImage}
      />
    </div>
  )
}

export default IndustrySpecialization
