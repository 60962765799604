export interface Team_Entity {
  id: number;
  name: string;
  title: string;
  specs: string;
  desc: string;
  pic: string;
  gradient: string;
  info: string;
  placements: string | null;
  phone: string;
  linkedin: string;
  big_pic: string;
  owner_detail?: any;
}

const teamData: Array<Team_Entity> = [
  // {
  //   id: 1,
  //   name: "Deanna Siedentopf",
  //   title: "Excecutive Recruiter",
  //   specs: "Real Estate & Property|Recent placement: Development Manager",
  //   desc: "After a little over two years in internal recruitment and close to five in HR.",
  //   pic: "about-person-1-v2",
  //   gradient:
  //     "linear-gradient(71.38deg, #EAF5FF 6.31%, #C7E5FF 88.3%), linear-gradient(71.38deg, #FFEFE1 6.31%, #FFEFBE 90.95%)",
  //   info: `When at work you'll find Danny with his AirPods glued to his ears, chatting to
  //   candidates and clients all day long, coffee in one hand and phone in the other, when
  //   he's not in the office you'll find him playing golf and let's just say he shouldn't quit his
  //   day job. Unlike on the golf course, in recruitment Dannys made a name for himself in
  //   the Property and Marketing sectors.`,
  //   placements:
  //     "General Manager - Property Development|State Manager - Acquisitions & Development|Account Director - Marketing & Advertising|Project Manager - Utilities|Commercial BDM - Utilities",
  //   phone: "0481 614 653",
  //   linkedin: "https://www.linkedin.com/in/danny-siedentopf-043a80147/",
  //   big_pic: "about-person-1-big",
  // },
  // {
  //   id: 2,
  //   name: 'Emma Bendix',
  //   title: 'Head of Sales',
  //   specs: 'Tech & Digital|Recent Placement: Marketing Specialist',
  //   desc: 'Emma is the glue that keeps the office together, her quirky personality and ...',
  //   pic: 'about-person-2',
  //   gradient: 'linear-gradient(71.11deg, #FFEBF2 9.98%, #FFD6C9 90.97%)',
  //   info: 'Emma is the glue that keeps the office together, her quirky personality and passion for making Blended the best is what helps keep the office in high spirits! Outside of work when she’s not renovating her first home or snuggling up with her cat Susa she’s slinging incense through her side hustle called Ikigai. When it’s time to catch a break you’ll find Emma in a deep immersion of sound healing in Bellingen resetting for the week ahead.',
  //   placements: null,
  //   phone: '0481 614 071',
  //   linkedin: 'https://www.linkedin.com/in/alexander-cooper-4a8008a4/',
  //   big_pic: 'about-person-2-big',
  //   owner_detail: {
  //     role: 'Head of Sales',
  //     category: 'Tech & Digital',
  //     recent_placement: 'Current Replacement: Marketing Specialist',
  //     description:
  //       'Emma is the glue that keeps the office together, her quirky personality and passion for making Blended the best is what helps keep the office in high spirits! Outside of work when she’s not renovating her first home or snuggling up with her cat Susa she’s slinging incense through her side hustle called Ikigai. When it’s time to catch a break you’ll find Emma in a deep immersion of sound healing in Bellingen resetting for the week ahead.'
  //   }
  // },
  // {
  //   id: 3,
  //   name: 'Alex Cooper',
  //   title: 'Executive Recruiter',
  //   specs: 'Commercial & Industrial|Recent Placement: National BDM',
  //   desc: "Think of your favourite music artist and there's a good chance Alex has seen the...",
  //   pic: 'about-person-3',
  //   gradient: 'linear-gradient(71.38deg, #FFEFE1 6.31%, #FFEFBE 90.95%)',
  //   info: 'Think of your favourite music artist and there’s a good chance Alex has seen them live. He is a restaurant loving, live music fanatic but most importantly, a people person and loves having a good yarn to his candidates and clients. Alex looks after all things industrial here at Blended from timber to pumps and everything in between, you name it he knows it.',
  //   placements:
  //     'Global Product Specialist - Machinery|Sales Manager - Building Materials (Timber)|Project Manager - Civil Construction|Senior Business Development Manager - Utilities|Marketing Specialist - Utilities',
  //   phone: '0481 614 487',
  //   linkedin: 'https://www.linkedin.com/in/alexander-cooper-4a8008a4/',
  //   big_pic: 'about-person-3-big',
  //   owner_detail: {
  //     role: 'Executive Recruiter',
  //     category: 'Commercial & Industrial',
  //     recent_placement: 'Recent Replacement: National BDM',
  //     description:
  //       'Think of your favourite music artist and there’s a good chance Alex has seen them live. He is a restaurant loving, live music fanatic but most importantly, a people person and loves having a good yarn to his candidates and clients. Alex looks after all things industrial here at Blended from timber to pumps and everything in between, you name it he knows it.'
  //   }
  // },
  {
    id: 5,
    name: "Declan Kluver",
    title: "Sales Director",
    specs: "Executive Recruiter|Sales Director",
    desc: "Declan founded Blended back in 2018 to make a change in the industry and be disruptive in a ...",
    pic: "about-person-5",
    gradient: "linear-gradient(66.75deg, #DDEFFF 0%, #FFE5DD 98.94%)",
    info: "Declan founded Blended back in 2018 to make a change in the industry and be disruptive in a traditional space. Declan’s philosophy of “Bringing People Together and Putting People First” is exactly what he does, he’s a passionate recruiter and leader, breaking the stigma associated with recruiters by doing good business. When not at work you’ll find Declan spending quality time with his wife Deanna, taking his son Archie to rugby or on the rare chance getting his nails painted by daughter Saige... She is so convincing.",
    placements:
      "Sales Director - Medtech|Marking Manager - Industrial Automation|Regional Manager - Manufacturing|National Business Development Manager - ERP Software|Technical Sales - Engineering",
    phone: "0481 613 761",
    linkedin: "https://www.linkedin.com/in/declan-kluver/",
    big_pic: "about-person-4-big",
    owner_detail: {
      role: "Sales Director",
      category: "Executive Recruiter",
      recent_placement: "Sales Director",
      description:
        "Declan founded Blended back in 2018 to make a change in the industry and be disruptive in a traditional space. Declan’s philosophy of “Bringing People Together and Putting People First” is exactly what he does, he’s a passionate recruiter and leader, breaking the stigma associated with recruiters by doing good business. When not at work you’ll find Declan spending quality time with his wife Deanna, taking his son Archie to rugby or on the rare chance getting his nails painted by daughter Saige... She is so convincing.",
    },
  },
  {
    id: 6,
    name: "Jordan Toyne",
    title: "Director",
    specs: "Tech & Digital|Recent Placement: Enterprise BDM",
    desc: "Jordan is a passionate recruiter, his light hearted approach makes him the natural relationship build...",
    pic: "about-person-7",
    gradient:
      "linear-gradient(71.38deg, #D9FFF8 6.31%, #FFF2BF 88.3%), linear-gradient(71.11deg, #FFEBF2 9.98%, #FFD6C9 90.97%)",
    info: "Jordan is a passionate recruiter, his light hearted approach makes him the natural relationship builder to all. Whether it's on the bike, in the gym or within the workplace, he’s always got a big smile on his face. <br /><br /> Jordan works between two locations, Torquay and Melbourne. Jordan is very proactive, he likes to be in the field building direct relationships face to face. He comes with a growth mindset and always keen to learn and further better himself. <br /><br /> He has a strong passion for endurance sports , being active with his Fiancé and two dogs (Lenny and Bruce). He also loves a barefoot adventure and getting in surf when the conditions are right.",
    placements:
      "Sales Director - Packaging|National Sales Manager - Building Materials|State Sales Manager - Building Materials|National Account Manager - Packaging|National Service Manager - Communications",
    phone: "0412 008 122",
    linkedin: "https://www.linkedin.com/in/jordantoyne",
    big_pic: "about-person-7-big",
    owner_detail: {
      role: "Recruitement Consultant",
      category: "Tech & Digital",
      recent_placement: "Enterprise BDM",
      description:
        "Jordan is a passionate recruiter, his light hearted approach makes him the natural relationship builder to all. Whether it's on the bike, in the gym or within the workplace, he’s always got a big smile on his face. <br /><br /> Jordan works between two locations, Torquay and Melbourne. Jordan is very proactive, he likes to be in the field building direct relationships face to face. He comes with a growth mindset and always keen to learn and further better himself. <br /><br /> He has a strong passion for endurance sports , being active with his Fiancé and two dogs (Lenny and Bruce). He also loves a barefoot adventure and getting in surf when the conditions are right.",
    },
  },
];

export default teamData;
