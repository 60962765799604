import { FixedHeader, Footer, Header } from 'components'
import { Hero, Sector, Contact } from './components'
import React from 'react'
import Helmet from 'react-helmet'

const IndustrySpecializations: React.FC = () => {
  return (
    <div className="mt-[32px] phone:mt-[16px]">
      <Helmet>
        <title>Industry Specializations Blended Employment</title>
        <meta name='description' content='Discover all job levels of industries at Blended Employment. We connect you with great businesses to your unique requirements. Find out more!'/>
        <meta name='keywords' content='employment consultant, sales and marketing recruitment'/>
        <link rel='canonical' href='https://blendedemployment.com.au/industry-specializations' />
      </Helmet>

      <Header />
      <Hero />
      <Sector />
      <Contact />
      <Footer />
      <FixedHeader />
    </div>
  )
}
export default IndustrySpecializations
