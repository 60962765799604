const menus = [
  {
    id: 1,
    label: 'Live Jobs',
    link: '/job-board'
  },
  {
    id: 2,
    label: 'Industry Specialisations',
    link: '/industry-specializations'
  },
  {
    id: 3,
    label: 'About us',
    link: '/about-us'
  }
]

export default menus
