import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Reveal, Timeline, Tween } from 'react-gsap'

const InProgress = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  return (
    <div className="phone:block flex items-center">
      <Reveal threshold={0.095}>
        <Timeline
          target={
            <img
              src="/assets/images/landing-in-progress-desktop.png"
              alt=""
              className="w-full max-w-[739px] tablet:max-w-[661px] mt-[80px] phone:mt-[56px] 4k:max-w-[1600px]"
            />
          }
        >
          <Tween
            from={{ opacity: 0, transform: 'translateX(-30px)' }}
            duration={0.6}
          />
          <div className="ml-[119px] tablet:ml-[48px] phone:mt-[32px] tablet:mt-[75px]">
            <Timeline
              target={
                <>
                  <p className="font-bold text-h4 text-ambit-bold text-white tablet:text-bodylarge 4k:text-h1">
                    In Progress
                  </p>
                  <p className="text-bodymedium text-ambit-light text-white opacity-[80%] mt-[16px] tablet:text-bodysmall phone:max-w-[203px] 4k:text-h3 4k:leading-[50px]">
                    {isMobile
                      ? 'recruiters immediately contact the candidates to check the data.'
                      : ` Transparency is key and having the ability to see which
                candidates we are in live discussions with`}
                  </p>
                </>
              }
            >
              <Tween
                from={{ opacity: 0, transform: 'translateY(20px)' }}
                duration={0.6}
                target={0}
              />
              <Tween
                from={{ opacity: 0, transform: 'translateY(20px)' }}
                duration={0.6}
                target={1}
              />
            </Timeline>
          </div>
        </Timeline>
      </Reveal>
    </div>
  )
}

export default InProgress
