import React, { useRef } from 'react'
import { Reveal, Tween } from 'react-gsap'

import CardAnimation from './CardAnimation'

import SectorCard from './SectorCard'

interface Props {
  handleStartHiring(): void;
}

const PropertyRealEstate = ({ handleStartHiring }:Props) => {
  const cardRef = useRef<any>([])
  CardAnimation(cardRef)

  return (
    <div className="pb-[120px] tablet:pb-[80px] phone:pb-[56px] relative z-20">
      {/* Technology & Digital */}
      <div className="flex phone:flex-col justify-between items-center mt-[80px] tablet:mt-[64px]">
        <Reveal>
          <Tween from={{ opacity: 0, transform: 'translateX(-20px)' }}>
            <div>
              <h3 className="text-h3 tablet:text-h4 text-secondary-950 font-bold text-ambit-bold mb-[24px] phone:mb-[8px]">
                    Building & Construction
              </h3>
              <p className="text-ambit-light font-light text-secondary-900 tablet:text-bodysmall phone:text-bodysmall phone:mb-[24px]">
                    Our expertise expands <span className="font-semibold">
                    Building and Construction </span>
                    segment and role.
              </p>
            </div>
          </Tween>
          <Tween from={{ opacity: 0, transform: 'translateX(20px)' }}>
            <button
              onClick={handleStartHiring}
              className="text-bodymedium  text-ambit-semibold tablet-text-ambit-bold font-semibold py-[16px] tablet:py-[12px] tablet:px-[40px] px-[24px] bg-button rounded-[6px] text-white tablet:text-ambit-bold phone:w-full"
            >
                  Start Hiring
            </button>
          </Tween>
        </Reveal>
      </div>

      <div className="mt-[56px] tablet:mt-[40px] phone:mt-[24px] grid grid-cols-4 tablet:grid-cols-3 phone:grid-cols-2 gap-x-[32px] gap-y-[24px] phone:gap-x-[16px] phone:gap-y-[16px]">
        <SectorCard refContainer={(element:any) => { cardRef.current[0] = element }} icon="ic-building" name="Project Manager" />
        <SectorCard refContainer={(element:any) => { cardRef.current[1] = element }} icon="ic-building" name="Senior Project Manager" />
        <SectorCard refContainer={(element:any) => { cardRef.current[2] = element }} icon="ic-building" name="Superintendent" />
        <SectorCard refContainer={(element:any) => { cardRef.current[3] = element }} icon="ic-building" name="Contract Administrator" />
        <SectorCard
          refContainer={(element:any) => { cardRef.current[4] = element }}
          icon="ic-building"
          name="Project Director"
        />
        <SectorCard refContainer={(element:any) => { cardRef.current[5] = element }} icon="ic-building" name="Construction Manager" />
        <SectorCard refContainer={(element:any) => { cardRef.current[6] = element }} icon="ic-building" name="Estimator" />
        <SectorCard
          refContainer={(element:any) => { cardRef.current[7] = element }}
          icon="ic-building"
          name="Quantity Surveyor"
        />
        <SectorCard refContainer={(element:any) => { cardRef.current[8] = element }} icon="ic-building" name="Project Coordinator" />
        <SectorCard
          refContainer={(element:any) => { cardRef.current[9] = element }}
          icon="ic-building"
          name="Site Manager"
        />
        <SectorCard
          refContainer={(element:any) => { cardRef.current[10] = element }}
          icon="ic-building"
          name="Operations Manager"
        />
        <SectorCard refContainer={(element:any) => { cardRef.current[11] = element }} icon="ic-building" name="Development Manager" />
        <SectorCard refContainer={(element:any) => { cardRef.current[12] = element }} icon="ic-building" name="Assistant Development Manager" />
        <SectorCard refContainer={(element:any) => { cardRef.current[13] = element }} icon="ic-building" name="Design Manager" />
      </div>

    </div>
  )
}

export default PropertyRealEstate
