import React from 'react'

export const Illustration = (
  { className }:
    { className: string }
) => {
  return (
    <svg className={className} width="587" height="563" viewBox="0 0 587 563" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <g clipPath="url(#clip0_3502_72535)">
        <g filter="url(#filter0_f_3502_72535)">
          <ellipse cx="256.954" cy="270.39" rx="256.363" ry="60.3708" transform="rotate(-25.9295 256.954 270.39)" fill="url(#paint0_linear_3502_72535)" fillOpacity="0.38" />
        </g>

        <image href={require('./image.png')} width="587" height="563" x="0" y="0" />
      </g>
      <defs>
        <filter id="filter0_f_3502_72535" x="-145.188" y="-24.2518" width="804.284" height="589.283" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="85.0292" result="effect1_foregroundBlur_3502_72535" />
        </filter>
        <linearGradient id="paint0_linear_3502_72535" x1="431.248" y1="209.962" x2="252.844" y2="482.92" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3A6AD0" />
          <stop offset="1" stopColor="#9BB0DD" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const TabletIllustration = (
  { className }:
    { className: string }
) => {
  return (
    <svg className={className} width="572" height="438" viewBox="0 0 572 438" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_3704_94846)">
        <ellipse cx="307.327" cy="224.954" rx="195.618" ry="46.066" transform="rotate(-25.9295 307.327 224.954)" fill="url(#paint0_linear_3704_94846)" fillOpacity="0.38" />
      </g>
      <image href={require('./tabletRepresent.png')} width="572" height="438" x="0" y="0" />
      <defs>
        <filter id="filter0_f_3704_94846" x="0.471069" y="0.127319" width="613.71" height="449.654" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="64.8817" result="effect1_foregroundBlur_3704_94846" />
        </filter>
        <linearGradient id="paint0_linear_3704_94846" x1="440.322" y1="178.844" x2="304.191" y2="387.125" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3A6AD0" />
          <stop offset="1" stopColor="#9BB0DD" />
        </linearGradient>
      </defs>
    </svg>
  )
}
