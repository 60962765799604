/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { motion } from 'framer-motion'
import React, { useRef, useState } from 'react'
import { Reveal, Timeline, Tween } from 'react-gsap'
import { useMediaQuery } from 'react-responsive'
import useCloseClickOutside from 'utils/useCloseClickOutside'
import { CreateJobPosition, Description as DescriptionJobPosition } from './CreateJobPosition'
import { FacilitateClient, Description as DescriptionFacilitateClient } from './FacilitateClient'
import { SecureCandidate, Description as DescriptionSecureCandidate } from './SecureCandidate'
import { SourceCandidate, Description as DescriptionSourceCandidate } from './SourceCandidate'

// import Description from "./Description";

const menus = [
  {
    id: 1,
    title: 'Create Job Position',
    menu_name: 'job-position',
    component: CreateJobPosition,
    componentDescription: <DescriptionJobPosition />
  },
  {
    id: 2,
    title: 'Source Candidates',
    menu_name: 'source-candidate',
    component: SourceCandidate,
    componentDescription: <DescriptionSourceCandidate />
  },
  {
    id: 3,
    title: 'Facilitate Client',
    menu_name: 'facilitate-client',
    component: FacilitateClient,
    componentDescription: <DescriptionFacilitateClient />
  },
  {
    id: 4,
    title: 'Secure Candidate',
    menu_name: 'secure-candidate',
    component: SecureCandidate,
    componentDescription: <DescriptionSecureCandidate />
  }
]

const HowItWork = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const isTablet = useMediaQuery({ maxWidth: 1024 })

  const wrapperRefButtonMobile = useRef(null)
  const [showMobileDropdown, setShowMobileDropdown] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState(menus[0])
  const ChildComponent = activeTab.component

  useCloseClickOutside(wrapperRefButtonMobile, setShowMobileDropdown)

  return (
    <div className="pt-[120px] tablet:pt-[95px] phone:pt-[168px] relative pb-[120px] phone:pb-[56px]">
      <div className="absolute phone:top-[-261px] left-[12px]">
        <div
          className="w-[2px] h-[3200px] z-20 hidden phone:block  phone:h-[386px] landing-line-custom"
          style={{ background: 'rgba(255, 255, 255, 0.56)' }}
        ></div>
      </div>
      <img
        src={
          isTablet
            ? '/assets/images/shapes/landing-last-ornamet-tablet.svg'
            : '/assets/images/shapes/landing-last-ornamet.svg'
        }
        className="absolute right-0 translate-x-[164px] translate-y-[-150px] tablet:translate-y-[-70px] translate-x-[150px] 3xl:translate-x-[400px] phone:hidden"
        alt=""
      />
      <Reveal>
        <Timeline
          target={
            <>
              <h3 className=" text-center text-h2 tablet:text-h3 4k:text-h1 font-bold text-ambit-bold text-white phone:max-w-[327px] phone:text-left">
                Let’s Learn How it Works 👇
              </h3>
              <p className="text-bodymedium text-ambit-light text-white opacity-[56%] mt-[16px] text-center phone:text-left max-w-[736px] phone:max-w-[327px] phone:mx-0 mx-auto 4k:text-h4 4k:max-w-[1400px]">
                Finding the right candidate shouldn’t be a process of trial and
                error. The benefit you get from Blended Employment’s industry
                expertise and thorough sourcing process, will ensure you aren’t
                taking a gamble on your company’s biggest assets – your
                employees.
              </p>
            </>
          }
        >
          <Tween
            from={{ opacity: 0, transform: 'translateY(-10px)' }}
            target={0}
            key={0}
          />
          <Tween
            from={{ opacity: 0, transform: 'translateY(-10px)' }}
            target={1}
            key={1}
          />

          {/* Buton Mobile */}
          <Tween
            from={{ opacity: 0, transform: 'translateY(-10px)' }}
            duration={0.6}
          >
            <div ref={wrapperRefButtonMobile} className="phone:block hidden mt-[40px]">
              <button
                onClick={() => setShowMobileDropdown(!showMobileDropdown)}
                className="px-[24px] rounded-full py-[16px] bg-info-main text-white flex items-center justify-between w-full text-bodysmall text-ambit-bold"
              >
                {activeTab.title}
                <span>
                  <img
                    src="/assets/icons/ic-arrow-down.svg"
                    className={`filter-white ${showMobileDropdown ? 'rotate-[180deg]' : 'rotate-[0deg]'
                      }`}
                    alt=""
                  />
                </span>
              </button>
              <div
                className={`bg-white mt-[8px] rounded-[16px] overflow-hidden ${showMobileDropdown ? 'block' : 'hidden'
                  }`}
              >
                <ul>
                  {menus.map((menu, index) => {
                    return (
                      <li key={index}>
                        <button
                          onClick={() => {
                            setActiveTab(menu)
                            setShowMobileDropdown(false)
                          }}
                          className={`p-[16px] text-bodysmall text-ambit-semibold hover:bg-info-surface hover:text-info-main flex items-center justify-between block w-full overflow-hidden ${menu.id === activeTab.id
                            ? 'text-black'
                            : 'text-secondary-600'
                            }`}
                        >
                          <span className="block">{menu.title}</span>
                          {menu.id === activeTab.id && (
                            <img src="/assets/icons/ic-check.svg" alt="" />
                          )}
                        </button>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </Tween>
        </Timeline>
      </Reveal>
      {/* Learn how it works */}
      {/* tabs */}

      {/* Button Tab Desktop */}
      <div className="mb-[32px] tablet:mb-[36px] flex justify-end items-center mt-[56px] mr-[75px] tablet:mr-[10px] phone:hidden">
        <Reveal>
          <Timeline
            target={
              <>
                {menus.map((menu, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => setActiveTab(menu)}
                      className={`px-[24px] py-[16px] font-bold text-ambit-bold text-bodymedium tablet:text-bodysmall text-white rounded-full mr-[8px] tablet:mr-[4px] 4k:text-h4 4k:px-[42px] 4k:py-[32px] ${activeTab.id === menu.id ? 'bg-info-main' : 'text-ambit-light font-light'
                        }`}
                    >
                      {menu.title}
                    </button>
                  )
                })}
              </>
            }
          >
            {menus.map((item, index) => {
              return (
                <Tween
                  key={index}
                  from={{ opacity: 0, transform: 'translateY(30px)' }}
                  duration={0.4}
                  target={index}
                />
              )
            })}
          </Timeline>
        </Reveal>
        {/* <button className="px-[24px] py-[16px]  text-bodymedium tablet:text-bodysmall bg-button-main text-white rounded-full hover:bg-info-main mr-[8px] tablet:mr-[4px] ">
          Source Candidates
        </button>
        <button className="px-[24px] py-[16px]  text-bodymedium tablet:text-bodysmall bg-button-main text-white rounded-full hover:bg-info-main mr-[8px] tablet:mr-[4px]">
          Facilitate Client
        </button>
        <button className="px-[24px] py-[16px]  text-bodymedium tablet:text-bodysmall bg-button-main text-white rounded-full hover:bg-info-main mr-[8px] tablet:mr-[0px]">
          Secure Candidate
        </button> */}
      </div>

      <motion.div
        initial={{
          opacity: 0
        }}
        whileInView={{
          opacity: 1,
          transition: {
            delay: isMobile ? 1.75 : 1,
            duration: 1
          }
        }}
        viewport={{ once: true }}
        className="flex tablet:items-start phone:block mt-[32px] 4k:mt-[64px] 4k:justify-between 4k:ml-auto 4k:max-w-[3000px]"
      >
        <div className="mt-[0px] phone:mr-[12px] mr-[24px] 4k:mr-[48px] max-w-[264px] tablet:max-w-[204px] phone:max-w-[100%] 4k:max-w-[600px]">
          {activeTab.componentDescription}
          <ProgressBar activeTab={activeTab} />
        </div>

        <ChildComponent />
      </motion.div>
    </div>
  )
}

const ProgressBar = ({ activeTab }: { activeTab: any; }) => {
  return (
    <div
      style={{ background: 'rgba(255, 255, 255, 0.32)' }}
      className="h-[4px] max-w-[262px] 4k:max-w-[100%] rounded-full mt-[16px] 4k:mt-[32px] phone:max-w-[100%]"
    >
      <span
        className={`
          transition-all duration-1000
          block bg-info-main rounded-full 
          h-full`
        }
        style={{
          width: activeTab.id === 1
            ? '25%'
            : activeTab.id === 2
              ? '50%'
              : activeTab.id === 3 ? '75%' : '100%'
        }}
      ></span>
    </div>
  )
}

export default HowItWork
