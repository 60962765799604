import React from 'react'

const InputField = ({
  label,
  placeholder,
  onChange,
  value,
  onBlur,
  error,
  name,
  touched
}: {
  label?: string;
  placeholder?: string;
  onChange?: any;
  value?: string;
  onBlur?: any;
  error?: string;
  name?: string;
  touched?: any;
}) => {
  return (
    <div className="form-group">
      <label
        htmlFor="full_name"
        className="text-bodymedium tablet:text-bodysmall text-ambit-semibold mb-[14px] block 4k:text-h3"
      >
        {label} <span className="text-danger-main">*</span>
      </label>
      <input
        type="text"
        placeholder={placeholder}
        className="py-[16px] px-[24px] 4k:py-[32px] 4k:px-[32px] border border-secondary-300 rounded-[8px] w-full outline-none focus:border-primary text-bodymedium text-ambit-light placehoder:text-secondary-600 4k:text-h3 input-field"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
      />
      {error && touched && <span className="text-danger-main">{error}</span>}
    </div>
  )
}

export default InputField
