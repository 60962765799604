import React from 'react'
import { Reveal, Tween } from 'react-gsap'

export default function Hero () {
  return (
    <div className="relative 4k:h-[1000px]">
      <img
        className="absolute w-[3990.48px] tablet:top-[-202px] tablet:scale-[4] 2xl:scale-[2] 2xl:left-[200px] 2xl:top-[-570px] 3xl:top-[-914px] 3xl:scale-[2] z-10 phone:top-[224px]"
        src="/assets/images/shapes/industry-hero-shape-2.svg"
        alt=""
      />
      <div className="relative 4k:scale-[2.3] 4k:mt-[500px] z-20 max-w-[1117px] tablet:max-w-[912px] phone:max-w-[327px] w-[90%] mx-auto mt-[132px] phone:mt-[65px] flex phone:flex-col justify-between mb-[103px] tablet:mb-[80px] phone:mb-[52px]">
        <div className="space-y-[24px] max-w-[544px] w-full">
          <Reveal>
            <Tween from={{ opacity: 0, transform: 'translateX(-30px)' }}>
              <p className="text-bodymedium phone:text-bodysmall phone:text-center font-semibold text-secondary-600 ">
                ‍💼 &nbsp; Connect people in sales and marketing
              </p>
              <h1 className="text-h1 phone:text-h3 phone:text-center font-bold text-ambit-bold text-secondary-950 ">
                We Build Teams In Your Industry
              </h1>
              <div>
                <p className="text-bodylarge phone:text-bodymedium font-semibold text-ambit-semibold text-secondary-900 mb-[16px] phone:text-center">
                  We offer sales and marketing recruitment for mid level to
                  C-Suite positions.
                </p>
                <p className="text-bodymedium  phone:text-bodysmall phone:text-center font-light text-ambit-light text-secondary-900 tablet:max-w-[416.78px] phone:w-[318px]">
                  No two businesses are the same, and neither are our services.
                  Providing exceptional sales and marketing recruitment across
                  Australia with a focus on Industrial, Tech and Real Estate
                  sectors.
                </p>
              </div>
            </Tween>
          </Reveal>
        </div>
        <div className="hero-image max-w-[494px] w-full phone:mt-[40px]">
          <Reveal>
            <Tween from={{ opacity: 0, transform: 'translateX(30px)' }}>
              <img
                src="/assets/images/industry-hero-illustration-v2.png"
                alt=""
              />
            </Tween>
          </Reveal>
        </div>
      </div>
    </div>
  )
}
