import React, { useState } from 'react'

export const FixedHeaderContext = React.createContext<any>(null)

const FixedHeaderProvider = ({ children }: any) => {
  const [showFixedHeader, setShowFixedHeader] = useState(false)
  return (
    <FixedHeaderContext.Provider value={[showFixedHeader, setShowFixedHeader]}>
      {children}
    </FixedHeaderContext.Provider>
  )
}
export default FixedHeaderProvider
