import React from 'react'
import { Reveal, Timeline, Tween } from 'react-gsap'

const Connect = () => {
  return (
    <section className="bg-white relative overflow-hidden">
      <div className="max-w-[1120px] tablet:max-w-[912px] w-[90%] mx-auto pt-[120px] tablet:py-[80px] phone:pt-[56px] phone:pb-[0px] pb-[120px] phone:h-[845px]">
        <div className="translate-y-[10px] phone:translate-y-[0px]">
          <Reveal>
            <Timeline
              target={
                <>
                  <h2 className="text-h2 tablet:text-h3 text-ambit-bold font-bold text-secondary-950">
                    <span className="flex items-center">
                      We Connect
                      <img
                        src="/assets/images/we-connect-1.png"
                        className="ml-2 tablet:w-[40px] w-[56px]"
                        alt=""
                      />
                    </span>
                    <span className="flex items-center">
                      Great Business
                      <img
                        src="/assets/images/we-connect-2-v2.png"
                        className="ml-2 tablet:w-[64px] w-[88px]"
                        alt=""
                      />
                    </span>
                  </h2>
                  <p className="text-bodymedium text-ambit-light phone:max-w-[327px] max-w-[544px] tablet:max-w-[440px] mt-[24px]">
                    <span className="mb-[16px] block">
                      As a leading Brisbane & Melbourne sales & marketing
                      recruitment agency, we understand the importance of having
                      key people to build relationships with your customers and
                      grow your business. Your employees are the woven fabric
                      that holds your business together, which is why it’s
                      important to be confident in your selection process.
                    </span>
                    <span>
                      At Blended Employment, people are our speciality and our
                      passion. We pride ourselves on assisting businesses right
                      across Australia and New Zealand to find quality sales and
                      marketing employees who can help build relationships and
                      grow businesses.
                    </span>
                  </p>
                </>
              }
            >
              <Tween
                from={{ opacity: 0, transform: 'translateX(-10px)' }}
                duration={0.6}
              />
            </Timeline>
          </Reveal>
        </div>

        <div className="absolute phone:static phone:translate-x-[100px] phone:mt-[90px] bottom-[-300px] tablet:bottom-[-140px] right-[-200px] tablet:right-[-90px] phone:scale-[1.2] 4k:translate-x-[-1061px]">
          <img
            src="/assets/images/about-globe-v2.svg"
            className="w-[800px] tablet:w-[564px]"
            alt=""
          />
        </div>
      </div>
    </section>
  )
}

export default Connect
