import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrolToTop = () => {
    return window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <footer className="bg-primary 4k:py-[130px] pt-[56px] pb-[55px] tablet:pt-[56px] tablet:pb-[76px] phone:py-[56px] relative z-50 overflow-hidden">
        <img
          className="absolute w-[3106.69px] top-[-852px] phone:top-[116px] phone:scale-y-[4] phone:scale-x-[3] phone:left-[-219px] phone:rotate-[-30deg] tablet:top-[-537px] scale-[1.5] z-10"
          src="/assets/images/shapes/footer-shape-2.svg"
          alt=""
        />
        {/* max-w-[1120px] 4k:max-w-[2700px] */}
        <div className="tablet:max-w-[912px] phone:max-w-[327px] w-[90%] mx-auto phone:block flex justify-between relative z-50">
          <div>
            <div className="logo mb-[26px]">
              <img
                src="/assets/images/logo-white.svg"
                className="w-[96px] 4k:w-[200px]"
                alt=""
              />
            </div>
            <p className="max-w-[313px] tablet:max-w-[281px] 4k:max-w-[700px] phone:max-w-[100%] text-bodysmall text-[rgba(255,255,255,0.8)] text-ambit-light mb-[24px] 4k:text-h3">
              We understand that a business is nothing without the people who
              stand proudly behind it. Your employees are the woven fabric that
              holds your business together, which is why it’s important to be
              confident in your selection process.
            </p>
            <p className="text-bodysmall phone:text-bodymedium text-white font-bold text-ambit-bold 4k:text-h3">
              <a href="mailto:info@blendedemployment.com.au">
                <span className="mr-[9px]">info@blendedemployment.com.au</span>

                <img
                  src="/assets/icons/ic-email.svg"
                  className="inline-block"
                  alt=""
                />
              </a>
            </p>
          </div>
          <div className="flex justify-end phone:block phone:mt-[48px]">
            <div className="mr-[48px] 4k:mr-[100px]">
              <p className="text-bodysmall font-bold text-ambit-bold text-[rgba(255,255,255,56%)] mb-[16px] 4k:text-h3 4k:mb-[32px]">
                Features
              </p>
              <ul>
                <li>
                  <Link
                    onClick={scrolToTop}
                    to="/"
                    className="text-bodysmall font-semibold text-ambit-semibold text-white mb-[16px] block 4k:text-h4 4k:mb-[32px]"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={scrolToTop}
                    to="/job-board"
                    className="text-bodysmall font-semibold text-ambit-semibold text-white mb-[16px]  block 4k:text-h4 4k:mb-[32px]"
                  >
                    Live Jobs
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={scrolToTop}
                    to="/industry-specializations"
                    className="text-bodysmall font-semibold text-ambit-semibold text-white mb-[16px]  block 4k:text-h4 4k:mb-[32px]"
                  >
                    Industry Specialisation
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={scrolToTop}
                    to="/about-us"
                    className="text-bodysmall font-semibold text-ambit-semibold text-white mb-[16px]  block 4k:text-h4 4k:mb-[32px]"
                  >
                    About us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mr-[24px] 4k:mr-[100px] 4k:w-[300px] phone:mt-[48px]">
              <p className="text-bodysmall font-bold text-ambit-bold text-[rgba(255,255,255,56%)] mb-[16px] 4k:text-h3 4k:mb-[32px]">
                Find Us
              </p>
              <div className="phone:flex">
                {/* <div>
                  <p className="text-white text-bodysmall font-light text-ambit-light  max-w-[160px] phone:w-[176px] 4k:text-h4 4k:mb-[32px] 4k:max-w-[100%]">
                    Brisbane Office 85 Commercial Road, Teneriffe QLD 4005.
                  </p>
                  <a
                    target="_blank"
                    href="https://www.google.com/maps/place/85+Commercial+Rd,+Teneriffe+QLD+4005,+Australia/@-27.4541407,153.0427039,17z/data=!3m1!4b1!4m5!3m4!1s0x6b9159910ea5efbf:0xdf30f615c4ebe8!8m2!3d-27.4541455!4d153.0448926?hl=id"
                    className="text-bodysmall font-semibold text-ambit-semibold text-white my-[16px] block 4k:text-h3 4k:mb-[32px]"
                    rel="noreferrer"
                  >
                    View on Map
                  </a>
                </div> */}
                <div>
                  <p className="text-white text-bodysmall font-light text-ambit-light  max-w-[160px] phone:w-[176px] 4k:text-h4 4k:mb-[32px] 4k:max-w-[100%]">
                    Level 35, 477 Collins St, Melbourne VIC
                  </p>
                  <a
                    target="_blank"
                    href="https://maps.app.goo.gl/ztwDTWuX5GqLUNfc8"
                    className="text-bodysmall font-semibold text-ambit-semibold text-white my-[16px] block 4k:text-h3 4k:mb-[32px]"
                    rel="noreferrer"
                  >
                    View on Map
                  </a>
                </div>
              </div>
            </div>
            <div className="">
              <p className="text-bodysmall font-bold text-ambit-bold text-[rgba(255,255,255,56%)] mb-[16px]  4k:text-h3 4k:mb-[32px]">
                Ready to work with us?
              </p>
              <a
                target="_blank"
                href="https://calendly.com/jordan-gko4"
                className="block phone:inline-block py-[16px] px-[23px] text-black text-caption font-bold text-ambit-bold bg-warning-main rounded-[6px] text-center  4k:text-h4 4k:mb-[32px]"
                rel="noreferrer"
              >
                Let’s Work Together
              </a>
            </div>
          </div>
        </div>
      </footer>
      <div className="bottom-part bg-white py-[28px] 4k:py-[40px] relative z-40">
        {/* max-w-[1120px] 4k:max-w-[2700px] */}
        <div className="tablet:max-w-[912px] phone:max-w-[327px] w-[90%] mx-auto phone:block flex justify-between items-center phone:space-y-[24px]">
          <div className="flex">
            <a
              href="https://www.facebook.com/blendedemployment"
              className="flex items-center mr-[34px]"
            >
              <img
                src="/assets/icons/ic-fb.svg"
                className="inline-block mr-[10px] 4k:w-[40px]"
                alt=""
              />
              <span className="text-bodysmall font-semibold text-ambit-semibold text-black 4k:text-h3">
                Facebook
              </span>
            </a>
            <a
              href="https://www.linkedin.com/company/blended-employment/"
              className="flex items-center"
            >
              <img
                src="/assets/icons/ic-linkedin.svg"
                className="inline-block mr-[10px] 4k:w-[40px]"
                alt=""
              />
              <span className="text-bodysmall font-semibold text-ambit-semibold text-black 4k:text-h3">
                LinkedIn
              </span>
            </a>
          </div>
          <p className="text-bodysmall font-semibold text-ambit-semibold text-secondary-600 phone:hidden 4k:text-h3">
            © Blended Employment 2022. All rights reserved.
          </p>
          <div className="flex items-center">
            <p className="text-bodysmall font-semibold text-ambit-semibold text-secondary-950 4k:text-h3">
              Powered by:
            </p>
            <a
              href="https://xrecruiter.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/assets/icons/ic-xrecruiter.png"
                className="inline-block ml-[12px] 4k:w-[200px] w-[85px]"
                alt=""
              />
            </a>
          </div>
          <p className="text-bodysmall font-semibold text-ambit-semibold text-secondary-600 phone:block hidden">
            © Blended Employment 2022. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
