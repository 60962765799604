/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import ApiClient from 'lib/Api/Client'
import { locations } from 'screens/JobBoard/components/FilterCard/data'
import { v4 as uuidv4 } from 'uuid'
export const postApplyJob = async (data: any, job_id: number) => {
  return await ApiClient.request({
    method: 'POST',
    url: '/jobs/' + job_id + '/apply',
    data
  })
}

export const getJobCategories = async (params: any) => {
  return await ApiClient.request({
    method: 'GET',
    url: '/job_categories',
    params
  }).then((res) => res.data)
}

export const getJobs = async (params: any) => {
  return await ApiClient.request({
    method: 'GET',
    url: '/jobs',
    params
  }).then((res) => res.data)
}

export const getJobDetail = async (id: number, params: any) => {
  return await ApiClient.request({
    method: 'GET',
    url: '/jobs/' + id,
    params
  })
}
const ignoredCategories = [
  'replacement',
  'retained',
  'exclusive',
  'contingent',
  'sourcing needed',
  'sourcing completed'
]
export const getCountingCategories = async () => {
  const categories = await getJobCategories(null)

  const final_categories = await Promise.all(
    categories.map(async (category: any) => {
      return await ApiClient.get(
        'jobs?per_page=100&status=active&job_category_ids[]=' + category.id
      ).then((res) => {
        if (!ignoredCategories.includes(category.name.toLowerCase())) {
          return {
            id: category.id,
            name: category.name,
            total_count: res.data.total_count
          }
        }
      })
    })
  )
  return final_categories
}

export const getCountingLocation = async () => {
  const res = await ApiClient.get('jobs?per_page=100&status=active')
  const locations: Array<any> = []
  const jobs = res.data.results

  // var tempResult: any = {};

  const groupByLocation = jobs.reduce((group: any, job: any) => {
    const { address } = job
    group[address] = group[address] ?? []
    group[address].push(job)
    return group
  }, {})

  console.log(groupByLocation)

  Object.keys(groupByLocation).map((item) => {
    if (item !== 'null') {
      locations.push({
        id: uuidv4(),
        address: item,
        data: groupByLocation[item],
        count: groupByLocation[item].length
      })
    }
  })

  // for (let job of jobs) {
  //   if (job.address) {
  //     tempResult[job.address] = {
  //       address: job.address,
  //       count: tempResult[job.address] ? tempResult[job.address].count + 1 : 1,
  //     };
  //     if (!tempResult[job.address].data) {
  //       tempResult[job.address].data = [];
  //     }
  //     tempResult[job.address].data.push(job);
  //   }
  // }

  // let result = Object.values(tempResult);
  console.log(locations)
  return locations
}

export const fetcher = (url: string) =>
  ApiClient.get(url).then((res) => res.data)
