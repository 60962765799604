import React, { useRef, useState } from 'react'
import { Reveal, Tween, Timeline } from 'react-gsap'
import CardAnimation from './CardAnimation'

import SectorCard from './SectorCard'

const tabs = [
  {
    title: 'Sales',
    name: 'sales'
  },
  {
    title: 'Marketing',
    name: 'marketing'
  },
  {
    title: 'Technical',
    name: 'technical'
  }
]

interface Props {
  handleStartHiring(): void;
}

const TechnologyDigital = ({ handleStartHiring }: Props) => {
  const [activeTab, setActiveTab] = useState('sales')

  const cardRef1 = useRef<any>([])
  CardAnimation(cardRef1)

  const cardRef2 = useRef<any>([])
  CardAnimation(cardRef2)

  const cardRef3 = useRef<any>([])
  CardAnimation(cardRef3)

  return (
    <div className="relative z-20">
      {/* Technology & Digital */}
      <div className="flex justify-between phone:flex-col items-center mt-[87px] tablet:mt-[64px]">
        <Reveal>
          <Tween from={{ opacity: 0, transform: 'translateX(-20px)' }}>
            <div>
              <h3 className="text-h3 tablet:text-h4 text-secondary-950 font-bold text-ambit-bold mb-[24px] phone:mb-[8px]">
                Technology & Digital
              </h3>
              <p className="text-ambit-light font-light text-secondary-900 tablet:text-bodysmall phone:mb-[24px] phone:w-full">
                Our expertise expands
                <span className="font-semibold text-ambit-light mx-1 phone:inline-block">
                  Technology and Digital
                </span>
                segment and role.
              </p>
            </div>
          </Tween>
          <Tween from={{ opacity: 0, transform: 'translateX(20px)' }}>
            <button
              onClick={handleStartHiring}
              className="text-bodymedium text-ambit-semibold py-[16px] tablet:py-[12px] tablet:px-[40px] px-[24px] bg-button rounded-[6px] text-white tablet-text-ambit-bold phone:w-full"
            >
              Start Hiring
            </button>
          </Tween>
        </Reveal>
      </div>

      {/* tabs */}
      <Reveal>
        <div className="mt-[56px] overflow-scroll tablet:mt-[40px] largeDesktop:mt-[40px] phone:mt-[24px] flex items-center">
          <Timeline
            target={
              <>
                {tabs.map((tab) => {
                  return (
                    <button
                      key={tab.name}
                      onClick={() => setActiveTab(tab.name)}
                      className={[
                        'py-[16px] phone:py-[12px] tablet:py-[12px] tablet:px-[40px] phone:px-[36.5px] phone:mr-[0px] px-[40px] rounded-full  text-bodymedium text-white mr-[10px] phone:w-full',
                        tab.name === activeTab
                          ? 'bg-button text-white font-bold text-ambit-bold'
                          : 'text-secondary-950 text-ambit-semibold'
                      ].join(' ')}
                    >
                      {tab.title}
                    </button>
                  )
                })}
              </>
            }
          >
            <Tween
              from={{ opacity: 0, transform: 'translateY(20px)' }}
              target={0}
              duration={0.2}
            ></Tween>
            <Tween
              from={{ opacity: 0, transform: 'translateY(20px)' }}
              target={1}
              duration={0.2}
            ></Tween>
            <Tween
              from={{ opacity: 0, transform: 'translateY(20px)' }}
              target={2}
              duration={0.2}
            ></Tween>
          </Timeline>

          {/*
            <button className="py-[16px] px-[40px] rounded-full font-light text-ambit-light text-bodymedium text-secondary-950">
              Marketing
            </button> */}
        </div>
      </Reveal>

      {activeTab === 'sales' && (
        <div className="mt-[24px] grid grid-cols-4 tablet:grid-cols-3 phone:grid-cols-2 gap-x-[32px] gap-y-[24px] phone:gap-x-[16px] phone:gap-y-[16px]">
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[0] = element }}
            icon="ic-manager"
            name="Country Manager"
            title="CM"
          />
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[1] = element }}
            icon="ic-manager"
            name="VP of Sales"
            title=""
          />
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[2] = element }}
            icon="ic-manager"
            name="Sales Director"
            title="SD"
          />
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[3] = element }}
            icon="ic-manager"
            name="Business Development Manager"
            title="BDM"
          />
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[4] = element }}
            icon="ic-manager"
            name="Renewal Specialist"
            title=""
          />
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[5] = element }}
            icon="ic-manager"
            name="Customer Success Manager"
            title="CSM"
          />
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[6] = element }}
            icon="ic-manager"
            name="Account Executive"
            title="AE"
          />
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[7] = element }}
            icon="ic-manager"
            name="Inside Sales Representative"
            title="ISR"
          />
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[8] = element }}
            icon="ic-manager"
            name="Business Development Representative"
            title="BDR"
          />
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[9] = element }}
            icon="ic-manager"
            name="Sales Manager"
            title="SM"
          />
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[10] = element }}
            icon="ic-manager"
            name="Account Manager"
            title="AM"
          />
          <SectorCard
            refContainer={(element: any) => { cardRef1.current[11] = element }}
            icon="ic-manager"
            name="Sales Development Representative "
            title="SDR"
          />
        </div>
      )}

      {activeTab === 'marketing' && (
        <div className="mt-[24px] grid grid-cols-4 tablet:grid-cols-3 phone:grid-cols-2 gap-x-[32px] gap-y-[24px]  phone:gap-x-[16px] phone:gap-y-[16px]">
          <SectorCard
            refContainer={(element: any) => { cardRef2.current[0] = element }}
            icon="ic-manager"
            name="Chief Marketing Officer"
          />
          <SectorCard refContainer={(element: any) => { cardRef2.current[1] = element }} icon="ic-manager" name="Director of Marketing" />
          <SectorCard refContainer={(element: any) => { cardRef2.current[2] = element }} icon="ic-manager" name="Marketing Manager" />
          <SectorCard refContainer={(element: any) => { cardRef2.current[3] = element }} icon="ic-manager" name="E-Commerce Manager" />
          <SectorCard refContainer={(element: any) => { cardRef2.current[4] = element }} icon="ic-manager" name="Marketing Specialist" />
          <SectorCard refContainer={(element: any) => { cardRef2.current[5] = element }} icon="ic-manager" name="Marketing Consultant" />
          <SectorCard refContainer={(element: any) => { cardRef2.current[6] = element }} icon="ic-manager" name="Marketing Coordinator" />
          <SectorCard
            refContainer={(element: any) => { cardRef2.current[7] = element }}
            icon="ic-manager"
            name="Digital Marketing Manager"
          />
          <SectorCard refContainer={(element: any) => { cardRef2.current[8] = element }} icon="ic-manager" name="Marketing Analyst" />

        </div>
      )}

      {activeTab === 'technical' && (
        <div className="mt-[24px] grid grid-cols-4 tablet:grid-cols-3 phone:grid-cols-2 gap-x-[32px] gap-y-[24px]  phone:gap-x-[16px] phone:gap-y-[16px]">
          <SectorCard refContainer={(element: any) => { cardRef3.current[0] = element }} icon="ic-manager" name="Chief Technology Officer" title="CTO" />
          <SectorCard refContainer={(element: any) => { cardRef3.current[1] = element }} icon="ic-manager" name="Chief Innovation Officer CIO" title="CIO" />
          <SectorCard refContainer={(element: any) => { cardRef3.current[2] = element }} icon="ic-manager" name="VP of Engineering" />
          <SectorCard refContainer={(element: any) => { cardRef3.current[3] = element }} icon="ic-manager" name="Chief Architect" />
          <SectorCard refContainer={(element: any) => { cardRef3.current[4] = element }} icon="ic-manager" name="Software Architect" />
          <SectorCard
            refContainer={(element: any) => { cardRef3.current[5] = element }}
            icon="ic-manager"
            name="Engineering Project Manager"
          />
          <SectorCard refContainer={(element: any) => { cardRef3.current[6] = element }} icon="ic-manager" name="Technical Lead" />
          <SectorCard
            refContainer={(element: any) => { cardRef3.current[7] = element }}
            icon="ic-manager"
            name="Principal Software Engineer"
          />
          <SectorCard
            refContainer={(element: any) => { cardRef3.current[8] = element }}
            icon="ic-manager"
            name="Senior Software Engineer"
          />
          <SectorCard refContainer={(element: any) => { cardRef3.current[9] = element }} icon="ic-manager" name="Software Engineer" />
          <SectorCard refContainer={(element: any) => { cardRef3.current[10] = element }} icon="ic-manager" name="Software Developer" />
          <SectorCard
            refContainer={(element: any) => { cardRef3.current[11] = element }}
            icon="ic-manager"
            name="Junior Software Developer"
          />
          <SectorCard
            refContainer={(element: any) => { cardRef3.current[12] = element }}
            icon="ic-manager"
            name="Intern Software Developer"
          />
        </div>
      )}
    </div>
  )
}

export default TechnologyDigital
