/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { ArrowBtn } from 'components'
import { Reveal, Tween } from 'react-gsap'
import { useMediaQuery } from 'react-responsive'
// eslint-disable-next-line camelcase
import teamData, { Team_Entity } from './data'
import TeamModal from './TeamModal'
import { Swiper, SwiperSlide } from 'swiper/react'
import BackgroundGradient from './BackgroundGradient'

const TeamCard = ({
  image,
  bgImage,
  name,
  title,
  desc,
  specs,
  clasess,
  onClick,
  id
}: {
  id: number;
  image: string;
  bgImage?: string;
  name: string;
  title: string;
  desc: string;
  specs: Array<string> | null | undefined;
  clasess?: string;
  onClick: (id: number) => void;
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const isTablet = useMediaQuery({ maxWidth: 1024 })

  return (
    <div
      className={`team-card bg-white w-full max-w-none tablet:max-w-[348px] phone:max-w-[100%] border border-black rounded-[6px] py-[40px] tablet:pb-[24px] px-[24px] flex flex-col justify-between h-[616px] tablet:h-[536px] phone:h-[100%] ${clasess}`}
      style={{
        boxShadow: isMobile
          ? '-4px 4px 0px rgba(0,0,0,1)'
          : '-4px 4px 0px rgba(0,0,0,1)'
      }}
    >
      <div
        className="w-[216px] h-[216px] tablet:w-[112px] tablet:h-[112px] mx-auto rounded-full border border-black overflow-hidden"
        style={{
          background:
            bgImage ??
            'linear-gradient(71.38deg, #EAF5FF 6.31%, #C7E5FF 88.3%), linear-gradient(71.38deg, #FFEFE1 6.31%, #FFEFBE 90.95%)'
        }}
      >
        <img src={`/assets/images/${image}.png`} alt="" />
      </div>
      <div className="mt-[16px] text-center">
        <h4 className="text-h4 tablet:text-bodylarge text-ambit-bold font-bold text-info-pressed">
          {name}
        </h4>
        <p className="text-bodymedium text-ambit-light font-light mt-[4px]">
          {title}
        </p>
      </div>
      <ul className="mt-[24px]">
        {specs &&
          specs.map((spec: string, index: number) => {
            return (
              <li key={`${spec}-${index}`} className="flex items-start">
                <div className="w-[14px] h-[14px] mt-1 bg-primary rounded-full flex items-center justify-center mr-[10px] tablet:mr-[8px] absolute">
                  <div className="w-[6px] h-[6px] rounded-full bg-white"></div>
                </div>
                <span className="text-bodymedium text-ambit-light text-secondary-900 text-left ml-[25px]">
                  {spec}
                </span>
              </li>
            )
          })}
      </ul>
      <div className="h-[1px] bg-secondary-300 my-[16px]"></div>
      <p className="text-bodymedium phone:text-bodysmall text-ambit-light font-light text-secondary-600">
        {desc}
      </p>
      <button
        onClick={() => onClick(id)}
        className="bg-white rounded-[6px] py-[12px] w-full border border-button phone:font-semibold text-button mt-[24px] hover:bg-button hover:text-white transition-all"
      >
        {isTablet ? 'Detail' : `Detail About ${name}`}
      </button>
    </div>
  )
}

const Team = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const isTablet = useMediaQuery({ maxWidth: 1024 })

  const totalPage = isMobile ? 1 : 1

  const [currentPage, setCurrentPage] = useState(0)
  const [teams, setTeams] = useState<Array<Team_Entity>>(teamData)

  const [showModal, setShowModal] = useState(false)
  const [activeTeam, setActiveTeam] = useState<Team_Entity | null>(teamData[0])

  const modalRef = useRef<HTMLDivElement | null>(null)

  const [teamSwiper, setTeamSwiper] = useState<any>(null)

  const handleClickOutside = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const paginate_navs = []
  for (let i = 0; i <= totalPage; i++) {
    if (currentPage === i) {
      paginate_navs.push(
        <button
          key={i}
          onClick={() => teamSwiper.slideTo(i)}
          className="w-[8px] h-[8px] bg-primary rounded-full"
        ></button>
      )
    } else {
      paginate_navs.push(
        <button
          key={i}
          onClick={() => teamSwiper.slideTo(i)}
          className="w-[8px] h-[8px] bg-secondary-300 rounded-full"
        ></button>
      )
    }
  }

  // useEffect(() => {
  //   setTeams(paginate(teamData, currentPage, limit));
  // }, [isMobile, currentPage, limit]);

  const handleArrowClick = (direction: string) => {
    if (direction === 'left') {
      teamSwiper.slidePrev()
    } else {
      teamSwiper.slideNext()
    }
    // if (direction === "left") {
    //   if (currentPage > 1) setCurrentPage(currentPage - 1);
    //   else setCurrentPage(limit - 1);
    // } else {
    //   if (currentPage < totalPage) setCurrentPage(currentPage + 1);
    //   else setCurrentPage(1);
    // }
    // console.log(currentPage);
  }

  const handleShowModal = (id: number) => {
    const foundTeam = teams.find((team) => team.id === id)
    if (foundTeam) {
      setActiveTeam(foundTeam)
      setShowModal(true)
    }
    // setShowModal(true)
  }
  return (
    <>
      <section
        // style={{ zIndex: showModal ? 9999 : 50 }}
        className={['relative overflow-hidden'].join(' ')}
      >
        <img
          src={
            isTablet
              ? '/assets/images/shapes/team-ornament-tablet.svg'
              : isMobile
                ? '/assets/images/shapes/about-team-shape.svg'
                : '/assets/images/shapes/team-ornament-desktop.svg'
          }
          className="absolute top-[79px] largeDesktop:top-[80px] largeDesktop:right-[0px] right-0 largeDesktop:scale-[1.1] tablet:top-[55px] tablet:scale-y-[1.1] phone:top-[65px] phone:right-[-35px] phone:scale-[0.8] z-[-1] phone:z-0"
          alt=""
        />
        <BackgroundGradient className="absolute z-[-2] overflow-visible w-full h-auto tablet:top-[100px] phone:top-[300px]" />

        <div className="py-[120px] tablet:py-[80px] phone:pt-[56px] phone:pb-[48px] max-w-[890px] tablet:max-w-[640px] phone:max-w-[327px] phone:py-[56px] mx-auto">
          <Reveal>
            <Tween from={{ opacity: 0, transform: 'translateY(-20px)' }}>
              <h3 className="text-h2 tablet:text-h3 text-secondary-950 font-bold text-ambit-bold text-center mb-[56px]">
                Our Team
              </h3>
            </Tween>
          </Reveal>
          <Reveal threshold={0.125}>
            <Tween
              from={{ opacity: 0, transform: 'translateY(50px)' }}
              duration={1}
            >
              <div className="relative z-40">
                <ArrowBtn
                  direction="left"
                  onClick={() => handleArrowClick('left')}
                  classes="translate-x-[10px] phone:translate-x-[0px] phone:left-[-20px] left-[-30px] top-[260px] absolute z-30 hidden"
                  iconClassName={currentPage > 0 ? 'fill-[#0C0A1A]' : 'fill-[#BABBBF]'}
                />

                {/* <div className="team-cards flex items-stretch justify-center space-x-[36px] phone:block phone:space-x-0 relative z-20"> */}
                <Swiper
                  // loop
                  speed={875}
                  breakpoints={{
                    375: {
                      slidesPerView: 1,
                      spaceBetween: 20
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 10
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 36
                    }
                  }}
                  slidesPerView={2}
                  spaceBetween={36}
                  className={''}
                  onSlideChange={(swiper) => {
                    setCurrentPage(swiper.realIndex)
                  }}
                  onSwiper={(swiper) => setTeamSwiper(swiper)}
                >
                  {teams.map((team, index) => {
                    return (
                      <SwiperSlide className="" key={team.id}>
                        <TeamCard
                          id={team.id}
                          image={team.pic}
                          name={team.name}
                          title={team.title}
                          specs={team?.specs?.split?.('|')}
                          desc={team.desc}
                          bgImage={team.gradient}
                          onClick={handleShowModal}
                        />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>

                {/* </div> */}
                <ArrowBtn
                  direction="right"
                  onClick={() => handleArrowClick('right')}
                  classes="translate-x-[-20px]  phone:translate-x-[0px] absolute top-[260px] right-[-40px] phone:right-[-10px] z-30 hidden"
                  iconClassName={currentPage < totalPage ? 'fill-[#0C0A1A]' : 'fill-[#BABBBF]'}
                />
              </div>
              <div className="team-pagination flex items-center justify-center space-x-[8px] mt-[44px] phone:mt-[52px] md:hidden">
                {paginate_navs}
              </div>
            </Tween>
          </Reveal>
        </div>
        <TeamModal
          onClose={() => setShowModal(false)}
          showModal={showModal}
          team={activeTeam}
          modalRef={modalRef}
        />
      </section>
    </>
  )
}

export default Team
