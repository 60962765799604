/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react'
import ApiClient from 'lib/Api/Client'
import { useLocation } from 'react-router-dom'
import FilterCard from '../FilterCard'
import RecentJobs from '../RecentJobs'

function paramsToObject (entries: any) {
  const result: any = {}
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value
  }
  return result
}
export default function JobListing () {
  const location = useLocation()
  const [selectedCategories, setSelectedCategories] = useState<Array<number>>(
    []
  )
  const [selectedLocations, setSelectedLocations] = useState<Array<any>>([])

  const [params, setParams] = useState<
    | {
        per_page?: number;
        page?: number;
        query?: string;
        status?: string;
      }
    | any
  >({
    page: 1,
    query: ''
  })
  const [queryParams, setQueryParams] = useState('')

  const [isLoading, setIsLoading] = useState(true)
  const [jobData, setJobData] = useState<Array<any>>([])

  const [showResult] = useState(6)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    // convert query string to query params
    const search = location.search

    const urlParams = new URLSearchParams(search)
    const urlParamsEntries = urlParams.entries()
    const queryParamsObject = paramsToObject(urlParamsEntries)
    setParams({ ...params, ...queryParamsObject })
  }, [location])

  useEffect(() => {
    setQueryParams(
      `?${new URLSearchParams(params).toString()}`
    )
  }, [params])

  useEffect(() => {
    ApiClient.get(
      `/jobs?status=active&per_page=100&query=${params.query}`
    ).then((res) => {
      console.log(res.data.results)
      setJobData(res.data.results)
      setIsLoading(false)
    })
  }, [queryParams])

  // Handle Select Filter Location and Categories
  const handleSelectFilter = (id: number, filterType:string) => {
    if (filterType === 'category') {
      setIsLoading(true)

      // Selected Categories
      let copySelectedCategories = [...selectedCategories]
      if (copySelectedCategories.includes(id)) {
        copySelectedCategories = copySelectedCategories.filter(
          (item) => item !== id
        )
      } else {
        copySelectedCategories.push(id)
      }

      setTimeout(() => {
        setIsLoading(false)
      }, 1500)

      setCurrentPage(1)
      return setSelectedCategories(copySelectedCategories)
    } else if (filterType === 'location') {
      setIsLoading(true)

      let copySelectedLocations = [...selectedLocations]

      const isFound = copySelectedLocations.find((item) => item === id)
      if (isFound) {
        copySelectedLocations = copySelectedLocations.filter(
          (item) => item !== id
        )
      } else {
        copySelectedLocations.push(id)
      }

      setTimeout(() => {
        setIsLoading(false)
      }, 1250)

      setCurrentPage(1)
      return setSelectedLocations(copySelectedLocations)
    }
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1250)
  }

  // Filter Job
  const filterJob = useMemo(() => {
    return jobData.filter(job => {
      return (selectedCategories.length === 0 ? true : selectedCategories.includes(job?.category?.id)) &&
      (selectedLocations.length === 0 ? true : selectedLocations.includes(job?.address))
    })
  }, [jobData, selectedCategories, selectedLocations])

  // Pagination Data
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * showResult
    const lastPageIndex = firstPageIndex + showResult

    return filterJob.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, filterJob])

  return (
    <>
      <div className="max-w-[1120px] 4k:max-w-[2300px] tablet:max-w-[911px] w-[90%] mx-auto flex justify-between items-start phone:flex-col-reverse">
        <FilterCard
          selectedLocations={selectedLocations}
          selectedCategories={selectedCategories}
          handleSelectFilter={handleSelectFilter}
        />
        <RecentJobs
          selectedLocations={selectedLocations}
          selectedCategories={selectedCategories}
          handleSelectFilter={handleSelectFilter}

          isLoading={isLoading}
          data={currentTableData}
          handlePagination={(page:number) => setCurrentPage(page)}
          pagination={{
            current_page: currentPage,
            per_page: showResult,
            total_count: filterJob.length,
            total_pages: Math.ceil(filterJob.length / showResult)
          }}
        />
      </div>
      <div className="pb-[100px]"></div>
    </>
  )
}
